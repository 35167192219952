import enLang from "./entries/en-US";
import esLang from "./entries/es_ES";
import ptBrLang from "./entries/pt_BR";


const AppLocale: any = {
  en: enLang,
  pt: ptBrLang,
  es: esLang,

};

export default AppLocale;
