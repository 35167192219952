import React from "react";
import Button from "@mui/material/Button";
import IntlMessages from "@crema/utility/IntlMessages";
import { alpha, Box } from "@mui/material";
import WalletsInfo from "./walletsInfo";
import { Fonts } from "../../../../shared/constants/AppEnums";
import AppCard from "@crema/core/AppCard";
import { TotalPL } from "../../mock";
import { WalletsPanelData } from "../NotificationsPanel";

interface walletsPanelDataProps {
  walletsPanelData?: WalletsPanelData;
}

const Wallets: React.FC<walletsPanelDataProps> = ({ walletsPanelData }) => {
  return (
    <Box>

      <AppCard >
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Box
              component="h2"
              sx={{
                color: "text.primary",
                fontSize: 16,
                //
                mb: { xs: 4, sm: 4, xl: 3 },
                fontWeight: Fonts.BOLD,
              }}
            >
              <IntlMessages id="wallets" />
            </Box>

            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  mt: { xl: 3 },
                }}
              >
                <Box
                  component="h3"
                  sx={{
                    fontWeight: Fonts.BOLD,
                    fontSize: 20,
                    color: "primary.main",
                  }}
                >
                  {walletsPanelData?.QtdTotal? Intl.NumberFormat('pt-BR').format(Number(walletsPanelData?.QtdTotal)): "-"}
                </Box>
                <Box
                  component="p"
                  sx={{
                    color: "text.secondary",
                    fontSize: 14,
                    whiteSpace: "nowrap",
                    mb: 1,
                  }}
                >
                  <IntlMessages id="quantity" />

                </Box>
              </Box>
              <Box
                sx={{
                  ml: 20,
                  mt: { xl: 3 },
                  px: 2,
                }}
              >

                <Box
                  component="h3"
                  sx={{
                    fontWeight: Fonts.BOLD,
                    fontSize: 20,
                    color: "primary.main",
                  }}
                >
                  R$ {walletsPanelData?.NetWorth?Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2}).format(Number(walletsPanelData?.NetWorth)) : "-"}
                </Box>
                <Box
                  component="p"
                  sx={{
                    color: "text.secondary",
                    fontSize: 14,
                    whiteSpace: "nowrap",
                    mb: 1,
                  }}
                >
                  <IntlMessages id="networth" />
                </Box>
              </Box>

            </Box>
          </Box>

        </Box>
        <Box
          sx={{
            pt: { md: 1, lg: 1 },
          }}
        >
          <WalletsInfo walletsInfo={walletsPanelData} />
        </Box>
      </AppCard>
    </Box>
  );
};

export default Wallets;
