export interface LanguageProps {
  languageId: string;
  locale: string;
  name: string;
}

const languageData: LanguageProps[] = [
 
  {
    languageId: "spanish",
    locale: "es",
    name: "Español",
  },
   {
    languageId: "english",
    locale: "en",
    name: "English",
  },
  {
    languageId: "portugues",
    locale: "pt",
    name: "Português",
  }
];
export default languageData;
