import { useAuthUser } from '@crema/utility/AuthHooks';
import { Box, alpha } from '@mui/material';
import React from 'react';
import { BiMessageDetail } from 'react-icons/bi';
import { CgFileDocument } from 'react-icons/cg';
import { HiOutlineMailOpen } from 'react-icons/hi';
import { AiOutlineWarning } from 'react-icons/ai';
import { MdOutlineCake } from "react-icons/md";
import { useIntl } from 'react-intl';
import { Fonts } from 'shared/constants/AppEnums';
import { useNavigate } from 'react-router-dom';


// import { Container } from './styles';
const getWelcomeIcon = (iconType: string) => {
    switch (iconType) {
        case "HiOutlineMailOpen":
            return <HiOutlineMailOpen color="#0A8FDC" className="icon" />;
        case "BiMessageDetail":
            return <BiMessageDetail color="#0A8FDC" className="icon" />;
        case "MdOutlineCake":
            return <MdOutlineCake color="#0A8FDC" className="icon" />;
        case "AiOutlineWarning":
            return <AiOutlineWarning color="#0A8FDC" className="icon" />;
        default:
            return <CgFileDocument color="#0A8FDC" className="icon" />;
    }
};

interface NotificationsPanelItemProps {
    qtd?: number,
    text: string,
    icon: string,
    route: string
    
}

const NotificationPanelItem: React.FC<NotificationsPanelItemProps> = ({qtd, text, icon, route}) => {
    const { messages } = useIntl();
    console.log(text, "texto");
    const  navigate = useNavigate();
    return (
        <Box
            onClick={() => navigate(route)}
            sx={{
                px: 3,
                mt: 3,
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                "&:hover": {
                    cursor: "pointer",
                    "#box_avatar:hover" : {
                    }
                },
            }}
        >
            <Box
            id='box_avatar'
                sx={{
                    mr: 4,
                    backgroundColor: (theme) =>
                        alpha(theme.palette.primary.main, 0.1),
                    width: { xs: 26, md: 40 },
                    height: { xs: 26, md: 40 },
                    minWidth: { xs: 26, md: 40 },
                    fontSize: { xs: 20, md: 22 },
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: 1.25,
                    borderRadius: "50%",
                    marginBottom: 1.5,
                }}
            >
                {getWelcomeIcon(icon)}
            </Box>
            <Box
                sx={{ 
                    display: "flex",

                    color: "text.secondary",
                }}
            >
                <Box component="p">{text}</Box>
                <Box
                    sx={{
                        color: "text.primary",
                        fontWeight: Fonts.MEDIUM,
                        fontSize: 16,
                        position: "relative",
                        lineHeight: 1,
                        marginLeft: 2,
                        marginTop: 0.7,
                    }}
                    component="h5"
                >
                    {qtd? qtd : "-"}
                </Box>

            </Box>
        </Box>
    );
}

export default NotificationPanelItem;