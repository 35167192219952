import React from "react";
import Box from "@mui/material/Box";
import { Fonts } from "../../../../shared/constants/AppEnums";
import { BalanceCoins } from "../../mock";
import { green, red } from "@mui/material/colors";
import { WalletsPanelData } from "../NotificationsPanel";
import IntlMessages from "@crema/utility/IntlMessages";
import { useMediaQuery } from "@mui/material";
interface WalletsInfoProps {
  walletsInfo?: WalletsPanelData;
}

const WalletsInfo: React.FC<WalletsInfoProps> = ({walletsInfo }) => {
  const matches = useMediaQuery('(max-width:1400px)');
  return (
    <Box
      sx={{
        mx: -2,
        mt: 5,
        mb: { xl: 1 },
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          mt: { xl: 3 },
          px: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row"
          }}
        >
          <Box
            sx={{
              position: "relative",
            }}
          >
            <Box
              component="p"
              sx={{
                fontSize: 14,
                color: "text.secondary",
                mb: 2,
                fontWeight: Fonts.SEMI_BOLD,
              }}
            >
                <IntlMessages id="newaccounts.30" />
            </Box>
            <Box sx={{
               display: "flex",
               alignItems: "center",
               flexDirection: "row",
               justifyContent: "space-between",
            }}
            >
              <Box >
                <Box
                  component="h3"
                  sx={{
                    fontWeight: Fonts.MEDIUM,
                    fontSize: 16,
                    color: "primary.main",
                  }}
                >
                  {walletsInfo?.Qtd30?Intl.NumberFormat('pt-BR').format(Number(walletsInfo?.Qtd30))  : "-"}
                </Box>
                <Box
                  component="p"
                  sx={{
                    fontSize: 14,
                    color: "text.secondary",
                  }}
                >
                        <IntlMessages id="quantity" />
                </Box>
              </Box>

              <Box sx={{
                    ml: 6,
                  }}>
                <Box
                  component="h3"
                  sx={{
                    fontWeight: Fonts.MEDIUM,
                    fontSize: 16,
                    color: "primary.main",
                  }}
                >
                    {walletsInfo?.NetWorth30?Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2}).format(Number(walletsInfo?.NetWorth30)): "-" }
                </Box>
                <Box
                  component="p"
                  sx={{
                    fontSize: 14,
                    color: "text.secondary",
                  }}
                >
                  <IntlMessages id="declaredpl"/>
                </Box>
              </Box>              
            </Box>

          </Box>
          <Box
            sx={{
              ml:matches? 5: 25,
              position: "relative",
            }}
          >
            <Box
              component="p"
              sx={{
                fontSize: 14,
                color: "text.secondary",
                mb: 2,
                fontWeight: Fonts.SEMI_BOLD,
              }}
            >
              <IntlMessages id="newaccounts.now" />
            </Box>
            <Box sx={{
               display: "flex",
               alignItems: "center",
               flexDirection: "row",
               justifyContent: "space-between",
            }}
            >
              <Box >
                <Box
                  component="h3"
                  sx={{
                    fontWeight: Fonts.MEDIUM,
                    fontSize: 16,
                    color: "primary.main",
                  }}
                >
                  {walletsInfo?.QtdDay? Intl.NumberFormat('pt-BR').format(Number(walletsInfo?.QtdDay)): "-"}
                </Box>
                <Box
                  component="p"
                  sx={{
                    fontSize: 14,
                    color: "text.secondary",
                  }}
                >
                    <IntlMessages id="quantity" />
                </Box>
              </Box>

              <Box sx={{
                    ml: 6,
                  }}>
                <Box
                  component="h3"
                  sx={{
                    fontWeight: Fonts.MEDIUM,
                    fontSize: 16,
                    color: "primary.main",
                  }}
                >
                  {walletsInfo?.NetWorthDay? Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2}).format(Number(walletsInfo?.NetWorthDay)): "-"}
                </Box>
                <Box
                  component="p"
                  sx={{
                    fontSize: 14,
                    color: "text.secondary",
                  }}
                >
                  <IntlMessages id="value" />
                </Box>
              </Box>              
            </Box>

          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default WalletsInfo;
