import React from "react";
import Button from "@mui/material/Button";
import IntlMessages from "@crema/utility/IntlMessages";
import { alpha, Box } from "@mui/material";
import CoinsInfo from "./CoinsInfo";
import { Fonts } from "../../../../shared/constants/AppEnums";
import AppCard from "@crema/core/AppCard";
import { TotalBalanceData } from "../../mock";
import { Height } from "@mui/icons-material";

interface TotalBalanceProps {
  totalBalanceData: TotalBalanceData;
}

const TotalBalance: React.FC<TotalBalanceProps> = ({ totalBalanceData }) => {
  return (
    <Box>

      <AppCard sxStyle={{position: "relative",height: 137,  width: "100%"}}>
        <Box
          sx={{
            display: "flex",            
          }}
        >
          <Box
            sx={{
              position: "relative",
            }}
          >
            <Box
              component="h2"
              sx={{
                color: "text.primary",
                fontSize: 15,
               
                mb: { xs: 4, sm: 4, xl: 2 },
                fontWeight: Fonts.BOLD,
              }}
            >
              {totalBalanceData.title !== undefined? <IntlMessages id={totalBalanceData.title} /> :<IntlMessages id="meetings.title" />}
            </Box>
          </Box>
        </Box>
       
        <Box
          sx={{
              pt: { md: 1, lg: 1 },
          }}
        >
          <CoinsInfo coins={totalBalanceData.coins} />
        </Box>
      </AppCard>
    </Box>
  );
};

export default TotalBalance;
