import React from "react";
import { Fonts } from "../../../../shared/constants/AppEnums";
import { BalanceCoins, studentRankings } from "../../mock";
import { AppTableContainer } from "@crema";
import { Table, TableHead, TableBody, TableCell, TableRow, Box, List, TableContainer } from "@mui/material";
import { ActivesPanelData } from "../NotificationsPanel";
import IntlMessages from "@crema/utility/IntlMessages";
interface ActivesInfoProps {
  activesInfo: ActivesPanelData[],
  totalValues: {
    totalPL: number,
    totalQtd: number
  }
}

const ActivesInfo: React.FC<ActivesInfoProps> = ({ activesInfo, totalValues }) => {  
  activesInfo = activesInfo.sort((a,b) => (a.NetWorth < b.NetWorth) ? 1 : -1 )
  return (
    <TableContainer sx={{ 
      "&::-webkit-scrollbar": {
         width: 8
       },                
       "&::-webkit-scrollbar-thumb": {
         backgroundColor: "primary.main",         
         borderRadius: 2
       },
      height: 470, 
     }}>
      <Table className="table" sx={{ mt: 10, mb: 7 }}>
        <List>
          <TableHead>
          <TableRow
            sx={{
              position: "relative",
              "& .tableCell": {
                fontSize: 13,
                padding: 2,
                paddingTop: 0,
                fontWeight: Fonts.BOLD,
                whiteSpace: "nowrap",
                "&:first-of-type": {
                  paddingLeft: 5,
                },
                "&:last-of-type": {
                  paddingRight: 5,
                },
              },
            }}
          >
            <TableCell align="left" className="tableCell">
              <IntlMessages id="actives" />
            </TableCell>
            <TableCell align="left" className="tableCell">
              Qtd
            </TableCell>
            <TableCell align="left" className="tableCell">
              %
            </TableCell>
            <TableCell align="left" className="tableCell">
              <IntlMessages id="value" /> (R$)
            </TableCell>
            <TableCell align="left" className="tableCell">
              %
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        
            {activesInfo.map((data) => (
              <TableRow
                key={data.ActivePanelId}
                className="item-hover"
                sx={{
                  "& .tableCell": {
                    fontSize: 13,
                    padding: "12px 8px",
                    "&:first-of-type": {
                      paddingLeft: 5,
                    },
                    "&:last-of-type": {
                      paddingRight: 5,
                    },
                  },
                }}
                hover
              >
                <TableCell className="tableCell">
                  <Box display="flex" alignItems="center" flexWrap={"wrap"} maxWidth={200}>
                    <Box fontWeight={Fonts.MEDIUM} >{data.NameActiveType}</Box>
                  </Box>
                </TableCell>
                <TableCell className="tableCell" >
                  <Box>{Intl.NumberFormat('pt-BR').format(Number(data.Qtd))}</Box>
                </TableCell>
                <TableCell className="tableCell">
                  <Box>{
                        //String((data.Qtd * 100) / totalValues.totalQtd).replace(".", ",").substring(0, 4)
                        Intl.NumberFormat('pt-BR', {style: 'percent',minimumFractionDigits: 2}).format(Number(data.Qtd / totalValues.totalQtd ))
                    } </Box>
                </TableCell>
                <TableCell className="tableCell">
                  <Box>{Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(Number(data.NetWorth))}</Box>
                </TableCell>
                <TableCell className="tableCell">
                  <Box> {
                      //String((data.NetWorth * 100) / totalValues.totalPL).replace(".", ",").substring(0, 4)
                      Intl.NumberFormat('pt-BR', {style: 'percent',minimumFractionDigits: 2}).format(Number(data.NetWorth / totalValues.totalPL ))
                  } </Box>
                </TableCell>
              </TableRow>
            ))}
   
        </TableBody>
        </List>
      </Table>
      </TableContainer>
  );
};

export default ActivesInfo;
