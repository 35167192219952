import React from "react";
import Grid from "@mui/material/Grid";

import AppComponentCard from "@crema/core/AppComponentCard";
import AppComponentHeader from "@crema/core/AppComponentHeader";
import AppGridContainer from "@crema/core/AppGridContainer";
import Selectable from "./Selectable";


export const Calendar = () => {
  return (
    <>
      <AppComponentHeader
        title="Calendário"
        refUrl="http://intljusticemission.github.io/react-big-calendar/examples/index.html#basic"
      />

      <AppGridContainer>
        <Grid item xs={12}>
          <AppComponentCard
            title="Agenda"
            component={Selectable}
          />
        </Grid>
      </AppGridContainer>
    </>
  );
};

export default Calendar;
