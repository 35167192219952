import React, { useEffect, useState } from "react";
import { Formik } from "formik";

import { useDispatch } from "react-redux";
import moment from "moment";
import IntlMessages from "@crema/utility/IntlMessages";

import { useIntl } from "react-intl";
import AddTaskForm from "./AddTaskForm";
import jwtAxios from "@crema/services/auth/jwt-auth";
import { AppAnimate, AppCard, AppGridContainer } from "@crema";

interface EventsData {
  EventId: string;
  ScheduleId: string;
  UserId: string;
  EventTypeId: string;
  Title: string;
  Description: string;
  PhoneNumber: string;
  Email: string;
  Place: string;
  DtStart: string;
  DtEnd: string;
  hourStart: string;
  hourEnd: string;
  Whats: string;
  Site: string;
  Imagem: string;
  Street: string;
}

interface EventProps {
  selectedEvent: any;
}
const AddNewTask: React.FC<EventProps> = ({ selectedEvent }) => {
  const { messages } = useIntl();
  const [taskLabels, setTaskLabels] = useState<[]>([]);

  return (
    <AppAnimate animation="transition.slideUpIn" delay={200}>
      <AppCard contentStyle={{ overflowY: "scroll" }}>
        <AddTaskForm
          selectedEvent={selectedEvent}
        />
      </AppCard>
    </AppAnimate>
  );
};

export default AddNewTask;
