import React from "react";
import Button from "@mui/material/Button";
import IntlMessages from "@crema/utility/IntlMessages";
import { alpha, Box } from "@mui/material";
import ActivesInfo from "./Info";
import { Fonts } from "../../../../shared/constants/AppEnums";
import AppCard from "@crema/core/AppCard";
import { TotalPL } from "../../mock";
import { ActivesPanelData } from "../NotificationsPanel";

interface TotalPLProps {
  activesPanelData: ActivesPanelData[];
}

const Actives: React.FC<TotalPLProps> = ({ activesPanelData }) => {
  let totalQtd = 0;
  let totalPL = 0;

  activesPanelData.forEach((active) => {
    totalQtd += active.Qtd;
    totalPL += active.NetWorth;
  });
  
  return (
    <Box>

      <AppCard sxStyle={{}}>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Box
              component="h2"
              sx={{
                color: "text.primary",
                fontSize: 16,
                //
                mb: { xs: 4, sm: 4, xl: 3 },
                fontWeight: Fonts.BOLD,
              }}
            >
              <IntlMessages id="actives" />
            </Box>
{/*
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  mt: { xl: 3 },
                }}
              >
                <Box
                  component="h3"
                  sx={{
                    fontWeight: Fonts.BOLD,
                    fontSize: 20,
                    color: "primary.main",
                  }}
                >
                  {Intl.NumberFormat('pt-BR').format(Number(totalQtd))}
                </Box>
                <Box
                  component="p"
                  sx={{
                    color: "text.secondary",
                    fontSize: 14,
                    whiteSpace: "nowrap",
                    mb: 1,
                  }}
                >
                  <IntlMessages id="quantity" />
                </Box>
              </Box>
              <Box
                sx={{
                  ml: 20,
                  mt: { xl: 3 },
                  px: 2,
                }}
              >

                <Box
                  component="h3"
                  sx={{
                    fontWeight: Fonts.BOLD,
                    fontSize: 20,
                    color: "primary.main",
                  }}
                >
                  R$ {Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(Number(totalPL))}
                </Box>
                <Box
                  component="p"
                  sx={{
                    color: "text.secondary",
                    fontSize: 14,
                    whiteSpace: "nowrap",
                    mb: 1,
                  }}
                >
                  <IntlMessages id="networth" />
                </Box>
              </Box>

            </Box>
*/}
          </Box>

        </Box>
        <Box
          sx={{
            pt: { md: 1, lg: 1 },
          }}
        >
          <ActivesInfo activesInfo={activesPanelData} totalValues={{ totalPL, totalQtd }} />
        </Box>
      </AppCard>
    </Box>
  );
};

export default Actives;
