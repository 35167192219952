import React, { useEffect, useState } from "react";
import { alpha, Box, Grid } from "@mui/material";
import { Fonts } from "../../../../shared/constants/AppEnums";
import { useIntl } from "react-intl";
import AppCard from "@crema/core/AppCard";

import { HiOutlineMailOpen } from "react-icons/hi";
import { BiMessageDetail } from "react-icons/bi";
import { CgFileDocument } from "react-icons/cg";
import AppScrollbar from "@crema/core/AppScrollbar";
import AppAnimate from "@crema/core/AppAnimate";
import { useAuthUser } from "@crema/utility/AuthHooks";
import { AppGridContainer } from "@crema";
import WelcomeCardItem from "./NotificationPanelItem";
import { GetAllBirthdaysOfTheMonth, GetAllBirthdaysOfTheDay } from "@crema/services/apis/evaData/requests";
import { welcomeCardData } from "pages/dashboard/mock";
import jwtAxios from "@crema/services/auth/jwt-auth";
import { red } from "@mui/material/colors";
import IntlMessages from "@crema/utility/IntlMessages";

const getWelcomeIcon = (iconType: string) => {
  switch (iconType) {
    case "HiOutlineMailOpen":
      return <HiOutlineMailOpen color="#0A8FDC" className="icon" />;
    case "BiMessageDetail":
      return <BiMessageDetail color="#0A8FDC" className="icon" />;
    default:
      return <CgFileDocument color="#0A8FDC" className="icon" />;
  }
};

export interface ActivesPanelData {
  ActivePanelId: string;
  ActivePanelTypesId: string;
  UserId: string;
  NameActiveType: string;
  Qtd: number;
  NetWorth: number;
}
export interface ContactsPanelData {
  ContactPanelId: string;
  ContactPanelTypesId: string;
  UserId: string;
  Incoming: string;
  Received: string;
  Now: string;
}
export interface ContributionsAndWithdrawalsPanelData {
  ContributionsAndWithdrawalsPanelId: string;
  UserId: string;
  Contribution: number;
  Withdrawal: number;
  DtInsert: Date;
}
export interface ContactsPanelTypesData {
  ContactPanelTypesId: string;
  Name: string;
  Description: string;
}

export interface CustomersRangePanelData {
  CustomerRangePanelId: string;
  InvestorRangeId: string;
  InvestorRangeName: string;
  UserId: string;
  Qtd: number;
  NetWorth: number;
}
export interface NotificationsPanelData {
  NotificationsPanelId: string;
  UserId: string;
  QtdDay: number;
  QtdMonth: number;
  Suitability10: number;
}
export interface WalletsPanelData {
  WalletPanelId: string;
  UserId: string;
  QtdTotal: number;
  Qtd30: number;
  QtdDay: number;
  NetWorth: number;
  NetWorth30: number;
  NetWorthDay: number;
}

export interface WelcomeCardData {
  id: number;
  type: string;
  counts: number;
  icon: string;
}

interface NotificationsPanelProps {
  data?: NotificationsPanelData;
}

const NotificationsPanel: React.FC<NotificationsPanelProps> = ({ data }) => {
  const { messages } = useIntl();
  const { user } = useAuthUser();
  
  return (
      <AppCard
        sxStyle={{
          mb: { xs: 5, md: 4 },
        }}
      >
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          <Box
            sx={{
              mr: { xs: 2, xl: 3 },
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                mb: { md: 6 },
              }}
            >
              <Box
                component="h2"
                sx={{
                  color: "text.primary",
                  fontSize: 25,
                  //
                  mb: { xs: 4, sm: 4, xl: 3 },
                  fontWeight: Fonts.BOLD,
                }}
              >
                <IntlMessages id="notifications.title" />
              </Box>
            </Box>
            <Box
              sx={{
                mx: -3,
              }}
            >
              <AppScrollbar
                sx={{
                  maxWidth: "100%",
                  px: 3,
                }}
              >

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    mx: -3,
                  }}
                >
                  <Grid container>
                    <Grid item xs={12} sm={12} style={{ display: "flex", justifyContent: "flex-start", padding: 4, flexDirection: "column" }}>
                      <WelcomeCardItem  qtd={data?.QtdDay} text={String(messages["birthday.day"])} icon="MdOutlineCake" route="/admin/investors/list?ps=d"/>
                      <WelcomeCardItem qtd={data?.QtdMonth} text={String(messages["birthday.month"])} icon="MdOutlineCake" route="/admin/investors/list?ps=m"/>
                      <WelcomeCardItem qtd={data?.Suitability10} text={String(messages["suitability.notification"])}  icon="AiOutlineWarning" route="/admin/investors/list?ps=s"/>
                    </Grid>
                  </Grid>
                </Box>
              </AppScrollbar>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-end",
              marginRight: -2.5,
              marginBottom: -5,
              maxWidth: { sm: 150, xl: 180 },
              "& img": {
                width: "100%",
                height: "100%",
                objectFit: "contain",
              },
            }}
          >
            <img alt="welcome" src={"/assets/images/dashboard/welcomImage.svg"} />
          </Box>
        </Box>
      </AppCard>
  );
};

export default NotificationsPanel;

