import { authRouteConfig } from "./auth";
import { initialUrl } from "shared/constants/AppConst";
import Error403 from "./errorPages/Error403";
import React from "react";
import { errorPagesConfigs } from "./errorPages";
import { adminPagesConfigs, adminNonPagesConfig } from "./admin";
//import { appsConfig } from "./apps";
import { samplePagesConfigs } from "./sample";


const authorizedStructure = {
  fallbackPath: "/signin",
  unAuthorizedComponent: <Error403 />,
  routes: [...samplePagesConfigs, ...adminPagesConfigs],
};

const unAuthorizedStructure = {
  fallbackPath: initialUrl,
  // routes: authRouteConfig,
  routes: [...authRouteConfig, ...adminNonPagesConfig]
};

const anonymousStructure = {
  routes: errorPagesConfigs,
};

export { authorizedStructure, unAuthorizedStructure, anonymousStructure };
