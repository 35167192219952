import { ptBR } from "@mui/material/locale";
import ptBrMessages from "../locales/pt_BR.json";

const PtBrLang = {
  messages: {
    ...ptBrMessages,
  },
  muiLocale: ptBR,
  locale: "pt-Br",
};
export default PtBrLang;
