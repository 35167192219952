import React, { useEffect, useState } from "react";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import { Box, Icon, ListItem, Typography } from "@mui/material";
import { Fonts } from "../../../shared/constants/AppEnums";
import * as FontAwesome from "react-icons/fa";
import jwtAxios from "@crema/services/auth/jwt-auth";


interface NotificationItemProps {
  item: {
    notificationsId: string;
    notificationsTypeId: string;
    title: string;
    description: string;
  };
}

const NotificationItem: React.FC<NotificationItemProps> = ({ item }) => {
  const [icon, setIcon] = useState('');
  const Icon = props => {
    const { iconName, size, color } = props;
    const render = React.createElement(FontAwesome[iconName]);
    return <div style={{ fontSize: size, color: color }}>{render}</div>;
  }

  useEffect(() => {
    initForm();
  }, [])

  async function initForm() {

    await jwtAxios.get("/notificationstype/GetById/" + item.notificationsTypeId).then((res) => {
      setIcon(res.data.icon);
    })
  }


  return (
    <ListItem
      sx={{
        padding: "8px 20px",
      }}
      className="item-hover"
    >
      <ListItemAvatar
        sx={{
          minWidth: 0,
          mr: 4,
        }}
      >
        {icon && <Icon iconName={icon} size={12} color="black" />}
      </ListItemAvatar>
      <Box
        sx={{
          fontSize: 14,
          color: (theme) => theme.palette.text.secondary,
        }}
      >
        <Typography>
          <Box
            component="span"
            sx={{
              fontSize: 14,
              fontWeight: Fonts.MEDIUM,
              mb: 0.5,
              color: (theme) => theme.palette.text.primary,
              mr: 1,
              display: "inline-block",
            }}
          >
            {item.title}
          </Box>
          {item.description}
        </Typography>
      </Box>
    </ListItem>
  );
};

export default NotificationItem;
