import React from "react";
import {  Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTenantParams } from "@crema/utility/AuthHooks";


interface AppLogoProps {
  color?: string;
}

const AppLogo: React.FC<AppLogoProps> = ({ color }) => {
  const navigate = useNavigate();
  const tenantParams = useTenantParams();
  return (
    <Box
    onClick={() => navigate("/admin/clickme/dashboard")}
      sx={{
        height: { xs: 56, sm: 70 },
        padding: 2.5,
        display: "flex",
        flexDirection: "row",
        cursor: "pointer",
        alignItems: "center",
        justifyContent: "center",
        "& svg": {
          height: { xs: 40, sm: 45 },
        },
      }}
      className="app-logo"
    >
     {/* <img
          style={{
            height: 30,
            marginRight: 10,
          }}
          src={"/assets/logos/clickme_escuro.png"}
          alt="crema-logo"
        /> */}
      <Box
        sx={{
          mt: 1,
          display: { xs: "none", md: "block" },
          "& svg": { 
            height: { xs: 25, sm: 30 },
          },
        }}
      >
        <img
          style={{
            objectFit: "cover",
            marginRight: 5,
            width: "200px",
           
          }}
          src={`/assets/logos/${tenantParams?.tenantName !== undefined? tenantParams?.tenantName: "clickme"}_logo.png`}
          alt="crema-logo"
        />
      </Box>
    </Box> 
  );
};

export default AppLogo;
