import  { ReactNode } from "react";

import {
  RiShieldUserLine,
} from 'react-icons/ri';
import { RoutePermittedRole } from "../shared/constants/AppConst";

export interface RouterConfigData {
  id: string;
  title: string;
  messageId: string;
  icon?: string | ReactNode;
  type: "item" | "group" | "collapse" | "divider";
  children?: RouterConfigData[];
  permittedRole?: RoutePermittedRole;
  color?: string;
  url?: string;
  exact?: boolean;
  count?: number;
}

const RoutesConfig: RouterConfigData[] =
[
  // {
  //   id: "adminMenu",
  //   title: "Admin",
  //   messageId: "sidebar.admin",
  //   type: "group",
  //   children: [
  //     {
  //       id: "colaborators",
  //       title: "Colaborators",
  //       messageId: "sidebar.colaborators",
  //       icon: <RiShieldUserLine />,
  //       type: "collapse",
  //       children: [
  //         {
  //           id: "colaborator_add",
  //           title: "Cadastro",
  //           messageId: "common.add",
  //           type: "item",
            
  //           url: "/admin/colaborators",
  //         },
  //         {
  //           id: "colaborator_find",
  //           title: "Listagem",
  //           messageId: "common.find",
  //           type: "item",
            
  //           url: "/admin/colaborators/list",
  //         },
  //       ],
  //     },
  //     {
  //       id: "menus",
  //       title: "Menus",
  //       messageId: "sidebar.menus",
  //       icon: <RiShieldUserLine />,
  //       type: "collapse",
  //       children: [
  //         {
  //           id: "menus_add",
  //           title: "Cadastro",
  //           messageId: "common.add",
  //           type: "item",
            
  //           url: "/admin/menus",
  //         },
  //         {
  //           id: "menus_find",
  //           title: "Listagem",
  //           messageId: "common.find",
  //           type: "item",
            
  //           url: "/admin/menus/list",
  //         },
  //       ],
  //     },
  //     {
  //       id: "modules",
  //       title: "Modules",
  //       messageId: "sidebar.modules",
  //       icon: <RiShieldUserLine />,
  //       type: "collapse",
  //       children: [
  //         {
  //           id: "modules_add",
  //           title: "Cadastro",
  //           messageId: "common.add",
  //           type: "item",
            
  //           url: "/admin/modules",
  //         },
  //         {
  //           id: "modules_find",
  //           title: "Listagem",
  //           messageId: "common.find",
  //           type: "item",
            
  //           url: "/admin/modules/list",
  //         },
  //       ],
  //     },
  //     {
  //       id: "profiles",
  //       title: "Profiles",
  //       messageId: "sidebar.profiles",
  //       icon: <RiShieldUserLine />,
  //       type: "collapse",
  //       children: [
  //         {
  //           id: "profiles_add",
  //           title: "Cadastro",
  //           messageId: "common.add",
  //           type: "item",
            
  //           url: "/admin/profiles",
  //         },
  //         {
  //           id: "modules_find",
  //           title: "Listagem",
  //           messageId: "common.find",
  //           type: "item",
            
  //           url: "/admin/profiles/list",
  //         },
  //       ],
  //     },
  //     {
  //       id: "Tentans",
  //       title: "Tenants",
  //       messageId: "sidebar.tenants",
  //       type: "collapse",
  //       children: [
  //         {
  //           id: "tenants_add",
  //           title: "Cadastro",
  //           messageId: "common.add",
  //           type: "item",
  //           //icon: <BiAlignLeft />,
  //           url: "/admin/tenants",
  //         },
  //         {
  //           id: "tenants_find",
  //           title: "Listagem",
  //           messageId: "common.find",
  //           type: "item",
  //          // icon: <BiAlignLeft />,
  //           url: "/admin/tenants/list",
  //         },
  //       ],
  //     },
  //     {
  //       id: "TypeTentans",
  //       title: "TypeTenants",
  //       messageId: "sidebar.typetenants",
  //       type: "collapse",
  //       children: [
  //         {
  //           id: "typetenants_add",
  //           title: "Cadastro",
  //           messageId: "common.add",
  //           type: "item",
  //           //icon: <BiAlignLeft />,
  //           url: "/admin/typetenants",
  //         },
  //         {
  //           id: "typetenants_find",
  //           title: "Listagem",
  //           messageId: "common.find",
  //           type: "item",
  //          // icon: <BiAlignLeft />,
  //           url: "/admin/typetenants/list",
  //         },
  //       ],
  //     },
  //     {
  //       id: "users",
  //       title: "Usuários",
  //       messageId: "sidebar.users",
  //       icon: <RiShieldUserLine />,
  //       type: "collapse",
  //       children: [
  //         {
  //           id: "user_add",
  //           title: "Cadastro",
  //           messageId: "common.add",
  //           type: "item",
            
  //           url: "/admin/users",
  //         },
  //         {
  //           id: "user_find",
  //           title: "Listagem",
  //           messageId: "common.find",
  //           type: "item",
            
  //           url: "/admin/users/list",
  //         },
  //       ],
  //     },
  //     {
  //       id: "languages",
  //       title: "Idiomas",
  //       messageId: "sidebar.languages",
  //       icon: <RiShieldUserLine />,
  //       type: "collapse",
  //       children: [
  //         {
  //           id: "language_add",
  //           title: "Cadastro",
  //           messageId: "common.add",
  //           type: "item",
  //           url:"/admin/languages",
  //         },
  //         {
  //           id: "language_find",
  //           title: "Listagem",
  //           messageId: "common.find",
  //           type: "item",
            
  //           url: "/admin/languages/list",
  //         },
  //       ],
  //     },
     
  //   ],
  // },  
];
export default RoutesConfig;
