import { authRole } from "../../../shared/constants/AppConst";

export const getUserFromAuth0 = (user: any) => {
  if (user)
    return {
      id: user.id,
      uid: user.uid,
      displayName: user.displayName,
      email: user.email,
      photoURL: user.photoURL,
      role: user.role,
      tenantId: user.tenantId,
      token : user.tokem,
      menu: user.menu
    };
  return user;
};

export const getUserFromFirebase = (user: any) => {
  if (user)
    return {
      id: 1,
      uid: user.uid,
      displayName: user.displayName ? user.displayName : "Crema User",
      email: user.email,
      photoURL: user.photoURL ? user.photoURL : "/assets/images/avatar/A11.jpg",
      role: authRole.user,
      
    };
  return user;
};
export const getUserFromAWS = (user: any) => {
  if (user)
    return {
      id: 1,
      uid: user.username,
      displayName: user.attributes.name ? user.attributes.name : "Crema User",
      email: user.attributes.email,
      photoURL: user.photoURL,
      role: authRole.user,
    };
  return user;
};

export const getUserFromJwtAuth = (user: any) => {
  if (user)
  return {
    cellPhone: user.CellPhone,
    cpf: user.Cpf,
    dtBirth: user.DtBirth,
    email: user.Email,
    findItens: user.FindItens,
    image: user.Image,
    isActive: user.IsActive,
    isWhatsapp: user.IsWhatsapp,
    maritalStatusId: user.MaritalStatusId,
    name: user.Name,
    nameFather: user.NameFather,
    nameMother: user.NameMother,
    nationality: user.Nationality,
    nbPis: user.NbPis,
    phone: user.Phone,
    profileId: user.ProfileId,
    rg: user.Rg,
    socialName: user.SocialName,
    surname: user.Surname,
    tenantId: user.TenantId,
    userId: user.UserId,
    role:  authRole.user,
  }
  //   return {
  //     id: user.Id,
  //     uid: user.uid,
  //     displayName: user.displayName,
  //     email: user.email,
  //     photoURL: user.photoURL,
  //     role:  authRole.user,
  //     tenantId: user.tenantId,
  //     token : user.tokem,
  //     menu: user.menu
  //   };
  // return user;
};
