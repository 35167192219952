import AppAnimate from '@crema/core/AppAnimate';
import AppCard from '@crema/core/AppCard';
import { Box, Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import NotificationsPainel, { ActivesPanelData, ContactsPanelData, ContributionsAndWithdrawalsPanelData, CustomersRangePanelData, NotificationsPanelData, WalletsPanelData, WelcomeCardData } from './NotificationsPanel';
import cryptoData, { contactPainelItemData, studentRankings, welcomeCardData, meetingsPainelData, accountData } from "../mock";
import AppGridContainer from '@crema/core/AppGridContainer';
import TotalBalance from './TotalBalance';
import Wallets from './Wallets';
import Actives from './Actives';

import Coins from './Coins';
import BuySell from './BuySell';
import Bitcoin from './Bitcoin';
import BtcVolumeCurrency from './BtcVolumeCurrency';
import PopularCoins from './PopularCoins';
import LatestNews from './LatestNews';
import CryptoMarketActivity from './CryptoMarketActivity';
import ContactsMade from './ContactsMade';
import ContactsNow from './Contacts';
import Meetings from './Meetings';
import CurrentAccount from './CurrentAccount';
import ContactsPanel from './Contacts';
import Chart from './chart';
import { AppGrid, AppLoader } from '@crema';
import Contribution from './Contribution';
import Custtomerrange from './Customerrange';
import { useIntl } from "react-intl";
import { Fonts } from 'shared/constants/AppEnums';
import { useAuthUser } from "@crema/utility/AuthHooks";
import { fetchError, fetchStart, fetchSuccess, showMessage } from 'redux/actions';
import jwtAxios from '@crema/services/auth/jwt-auth';
import { useDispatch } from 'react-redux';


const EvaDashboard: React.FC = () => {
    const { messages } = useIntl();
    const { user } = useAuthUser();
    const [notificationsData, setNotificationsData] = useState<WelcomeCardData[]>([]);
    const [contactsPanelData, setContactsPanelData] = useState<ContactsPanelData>({
        ContactPanelId: "",
        ContactPanelTypesId: "",
        UserId: "",
        Received: "",
        Incoming: "",
        Now: ""
    });
    const [activesPanelData, setActivesPanelData] = useState<ActivesPanelData[]>([]);
    const [contributionsAndWithdrawalsPanelData, setContributionsAndWithdrawalsPanelData] = useState<ContributionsAndWithdrawalsPanelData[]>([]);
    const [notificationsPanelData, setNotificationsPanelData] = useState<NotificationsPanelData | undefined>();
    const [customersRangePanelData, setCustomersRangePanelData] = useState<CustomersRangePanelData[]>([]);
    const [walletsPanelData, setWalletsPanelData] = useState<WalletsPanelData | undefined>();
    const [isLoadingPanelData, setIsLoadingPanelData] = useState(true);
    const dispatch = useDispatch();
   
    useEffect(() => {
        // jwtAxios.post("/investors/suitability", { isActive: true }).then((res) => {

        const suitability: WelcomeCardData =
        {
            id: 3,
            type: "Suitability",
            counts: 0,
            icon: "AiOutlineWarning",
        };
        setNotificationsData(current => [suitability, ...current]);
        // });
        jwtAxios.post("/investors/GetAllBirthdaysOfTheDay", { isActive: true, userId: user?.userId }).then((res) => {
            let birthDayOfDay: WelcomeCardData =
            {
                id: 1,
                type: "birthday.day",
                counts: res.data ? res.data : 0,
                icon: "HiOutlineMailOpen"
            };
            setNotificationsData(current => [birthDayOfDay, ...current]);
        });

        jwtAxios.post("/investors/GetAllBirthdaysOfTheMonth", { isActive: true, userId: user?.userId }).then((res) => {

            let birthDayOfMonth: WelcomeCardData =
            {
                id: 2,
                type: "birthday.month",
                counts: res.data ? res.data : 0,
                icon: "CgFileDocument",
            };
            setNotificationsData(current => [birthDayOfMonth, ...current]);
        });

        jwtAxios.post("/notificationsPanel/getbyfilter", { ParamSearch: { isActive: true } }).then((res) => {
            setNotificationsPanelData(res.data.List[0]);
        })
        // jwtAxios.post("/contactsPanel/getbyfilter", { ParamSearch: { isActive: true } }).then((res) => {
        //     setContactsPanelData(res.data.List)
        // })
        jwtAxios.post("/activesPanel/getbyfilter", { ParamSearch: { isActive: true, UserId: user?.userId } }).then((res) => {
            setActivesPanelData(res.data.List);
        }).finally(() => setIsLoadingPanelData(false))
        jwtAxios.post("/ContributionsAndWithdrawalsPanel/getbyfilter", { ParamSearch: { isActive: true } }).then((res) => {
            setContributionsAndWithdrawalsPanelData(res.data.List);
        })
        jwtAxios.post("/customersRangePanel/getbyfilter", { ParamSearch: { isActive: true, UserId: user?.userId } }).then((res) => {
            setCustomersRangePanelData(res.data.List);
        })
        jwtAxios.post("/walletsPanel/getbyfilter", { ParamSearch: { isActive: true, UserId: user?.userId } }).then((res) => {            
            setWalletsPanelData(res.data.List[0]);
        })

    }, [])
        return (
            isLoadingPanelData ? <AppLoader />:
        <AppGridContainer>
            <Grid item xs={12} lg={6} md={8} xl={5} sm={12} style={{ paddingBottom: 0, marginBottom: -2 }}>
                <NotificationsPainel data={notificationsPanelData} />
            </Grid>
            <Grid item xs={12} md={8} lg={6} xl={5} sm={12} spacing={0} style={{display: "flex", justifyContent: "space-between"}} >
                    <Grid item xs={12} md={4} lg={8} xl={7} sm={12} style={{ paddingBottom: 0, }}>
                        <ContactsPanel contactsPanelData={contactsPanelData} />
                    </Grid>
                    <Grid item xs={12} md={5} lg={4} xl={5} sm={12} style={{ paddingBottom: 0, margin: "0 10px"}}>
                        <AppGridContainer>
                            <Grid item xs={12} md={12} lg={12} xl={12} sm={12} style={{ paddingBottom: 0}}>
                                <Meetings totalBalanceData={meetingsPainelData} />
                            </Grid>
                            <Grid item xs={12} md={12} lg={12} xl={12} sm={12} style={{ paddingBottom: 0 }}>
                                <CurrentAccount CurranteAccountData={accountData} />
                            </Grid>
                        </AppGridContainer>
                    </Grid>
            </Grid>
            <Grid item xs={12} lg={6} md={6} xl={5} sm={12}>
                <AppGridContainer>
                    <Grid item xs={12} lg={12} md={12} xl={12} sm={12}>
                        <Wallets walletsPanelData={walletsPanelData} />
                    </Grid>
                    <Grid item xs={12} lg={12} md={12} xl={12} sm={12}>
                        <Chart />
                    </Grid>
                </AppGridContainer>
            </Grid>
            <Grid item xs={12} lg={6} md={6} xl={5} sm={12}>
                <Actives activesPanelData={activesPanelData} />
            </Grid>
            <Grid item xs={12} lg={6} md={6} xl={5} sm={12}>
                <Custtomerrange customerRangeData={customersRangePanelData} />
            </Grid>
            <Grid item xs={12} lg={6} md={6} xl={5} sm={12}>
                <Contribution contributionsAndWithdrawalsPanelData={contributionsAndWithdrawalsPanelData} />
            </Grid>

        </AppGridContainer>
    )
};
export default EvaDashboard;