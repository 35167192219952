import React, { useEffect, useState } from "react";
import IntlMessages from "@crema/utility/IntlMessages";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { Form, Formik } from "formik";
import MenuItem from "@mui/material/MenuItem";
import * as yup from "yup";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import { useIntl } from "react-intl";
import AppGridContainer from "@crema/core/AppGridContainer";
import Grid from "@mui/material/Grid";
import AppTextField from "@crema/core/AppFormComponents/AppTextField";
import { styled, useTheme } from "@mui/material/styles";
import { LabelObj, PriorityObj, StaffObj } from "types/models/Todo";
import { Fonts } from "shared/constants/AppEnums";
import jwtAxios from "@crema/services/auth/jwt-auth";
import { DesktopDatePicker, LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import dayjs from 'dayjs';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { Checkbox, FormControlLabel, OutlinedInput, Theme } from "@mui/material";
import { useAuthUser } from "@crema/utility/AuthHooks";
import { useDispatch } from "react-redux";
import { fetchStart, fetchSuccess, showMessage } from "redux/actions";
import moment from "moment";
import AppInfoView from "@crema/core/AppInfoView";

const StyledDivider = styled(Divider)(({ theme }) => ({
  marginTop: 20,
  marginBottom: 20,
  [theme.breakpoints.up("xl")]: {
    marginTop: 32,
    marginBottom: 32,
  },
}));

interface AddTaskFormProps {
  values: any;
  isSubmitting: boolean;

  //   taskLabels: LabelObj[];
}
interface ClientsData {
  userId: string;
  name: string;
}

interface EventsData {
  EventId: string;
  ScheduleId: string;
  UserId: string;
  EventTypeId: string;
  Title: string;
  Description: string;
  PhoneNumber: string;
  Email: string;
  Place: string;
  DtStart: Date;
  DtEnd: Date;
  Whats: string;
  Site: string;
  Imagem: string;
  Street: string;
}
interface EventsTypesData {
  eventTypeId: string;
  name: string;
}
interface EventProps {
  selectedEvent: any;
}
const AddTaskForm: React.FC<EventProps> = ({ selectedEvent }) => {
  
  const theme = useTheme();
  const [personName, setPersonName] = React.useState<string[]>([]);
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  function getStyles(name: string, personName: readonly string[], theme: Theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }
  const handleMultiChange = (event: SelectChangeEvent<typeof personName>) => {
    const {
      target: { value },
    } = event;
    setClientsNames(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };
  const handleClientsChange = (event: SelectChangeEvent<typeof personName>) => {
    const {
      target: { value },
    } = event;
    setClientsNames(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };
  const [eventsTypes, setEventsTypes] = useState<EventsTypesData[]>([]);
  const [events, setEvents] = useState<EventsData[]>([]);
  const [clients, setClients] = useState<ClientsData[]>([]);
  const [optionalVisibility, setOptionalVisibility] = useState<boolean>(false);
  const [startDate, setStartDate] = React.useState<Date | null>(null);
  const [endDate, setEndDate] = React.useState<Date | null>(null);
  const [descriptionData, setDescription] = React.useState<string>("");

  const [clientsNames,setClientsNames] = React.useState<string[]>([]);
  const [hourStart, setHourStart] = React.useState<dayjs.Dayjs | null>(
    (dayjs('2020-01-01 12:00')),
  );
  const [hourEnd, setHourEnd] = React.useState<dayjs.Dayjs | null>(
    (dayjs('2020-01-01 12:00')),
  );

  const handleDtEndChange = (newValue: Date | null) => {
    setEndDate(newValue);
  };
  const handleDtStartChange = (newValue: Date | null) => {
    setStartDate(newValue);
  };

  const handleHourStartChange = (newValue: dayjs.Dayjs | null) => {
    console.log(newValue, "hora");
    setHourStart(newValue);
  };
  const handleHourEndChange = (newValue: dayjs.Dayjs | null) => {
    setHourEnd(newValue);
  };
  const handleDescriptionChange = (newValue: string) => {
    setDescription(newValue);
  };

  const isSameOrBefore = (startTime, endTime) => {
    console.log(startTime, endTime, "startend")
    return moment(startTime, 'HH:mm').isSameOrBefore(moment(endTime, 'HH:mm'));
  }
  const { messages } = useIntl();
  const dispatch = useDispatch();
  const { user, scheduleId } = useAuthUser();
  const validationSchema = yup.object({
    Title: yup.string().required(String(messages["validation.titleRequired"])),
  //   hourStart: yup.string()
  // .test(
  //   'not empty',
  //   'Start time cant be empty',
  //   function(value) {
  //     return !!value;
  //   }
  // )
  // .test(
  //   "start_time_test",
  //   "Start time must be before end time",
  //   function(value) {
  //     const { hourEnd } = this.parent;
  //     return isSameOrBefore(value, hourEnd);
  //   }
  // ),
    // dtStart: yup.date().max(new Date(),'Não é possível incluir uma data futura'), 
    // dtEnd: yup.date().default(endDate)
    // .when("dtStart",
    //     (dtStart, yup) => dtStart && yup.min(dtStart, "End time cannot be before start time"))

  });
  useEffect(() => {
  
    //vindo vazio
    jwtAxios.post("eventstypes/getbyfilter", { ParamSearch: { isActive: true}}).then((response) => {
      setEventsTypes(response.data.List);
    })

    // //vindo vazio
    // jwtAxios.post("investors/getbyfilter", { userId: user?.userId }).then((response) => {
    //   setClients(response.data);
    // })

     jwtAxios.post("users/getbyfilter", { ParamSearch: { isActive: true}}).then((response) => {
       let accessorsArray: any[] = [];
      response.data.List.map((accessor) => {
        if(accessor.UserId !== user?.userId){
          accessorsArray.push({userId: accessor?.UserId, name: accessor?.Name + " " + accessor?.Surname});
        }
      })
      console.log(accessorsArray, "accessors Array")
      setClients(accessorsArray);
    })

    dispatch(fetchSuccess())
  }, [])
  console.log(user?.userId, "userID");

  let labelList: LabelObj[] = [];
  let priorityList: PriorityObj[] = [];
  let staffList: StaffObj[] = [];
  // function addHours(numOfHours, date = new Date()) {
  //   date.setTime(date.getTime() + numOfHours * 60 * 60 * 1000);

  //   return date;
  // }
  return (
    <Formik
      validateOnChange={true}
      enableReinitialize
      initialValues={{
        EventId: selectedEvent?.EventId ? selectedEvent?.EventId : "",
        ScheduleId: scheduleId,
        UserId: selectedEvent?.UserId ? selectedEvent?.UserId : "",
        Title: selectedEvent?.Title ? selectedEvent?.Title : "",
        Description: selectedEvent?.Desc ? selectedEvent?.Desc : "",
        PhoneNumber: selectedEvent?.PhoneNumber ? selectedEvent?.PhoneNumber : "",
        Email: selectedEvent?.Email ? selectedEvent?.Email : "",
        Place: selectedEvent?.Place ? selectedEvent?.Place : "",
        DtStart: startDate === null ? selectedEvent?.Start : startDate,
        DtEnd: endDate === null ? selectedEvent?.End : endDate,
        HourStart: startDate === null ? selectedEvent?.HourStart : startDate,
        HourEnd: endDate === null ? selectedEvent?.HourEnd : endDate,
        EventTypeId: selectedEvent?.EventTypeId ? selectedEvent?.EventTypeId : "",
      }}
      validationSchema={validationSchema}
      onSubmit={(data, { setSubmitting, resetForm }) => {
        dispatch(fetchStart());
         console.log(data, "data");

        var month = data.DtStart.getMonth() + 1;
        var year = data.DtStart.getFullYear();
        var day = data.DtStart.getDate();
        let hourStartDate = dayjs(hourStart).format("HH:mm:ss");
        let hourEndDate = dayjs(hourEnd).format("HH:mm:ss");

        if (day < 10) day = "0" + day;

        var formattedStartDate = year + "-" + month + "-" + day + " " + hourStartDate;

        var endMonth = data.DtEnd.getMonth() + 1;
        var endYear = data.DtEnd.getFullYear();
        var endDay = data.DtEnd.getDate();


        if (endDay < 10) endDay = "0" + endDay;

        var formattedEndDate = endYear + "-" + endMonth + "-" + endDay + " " + hourEndDate;

        console.log(hourStart, "hourStart");
        console.log(hourEnd, "hourEnd");



        setSubmitting(true);
        const newTask = {
          EventId: data.EventId !== null? data.EventId : "",
          ScheduleId: scheduleId,
          UserId: user?.userId,
          PhoneNumber: data.PhoneNumber,
          Email: data.Email,
          Place: data.Place,
          Title: data.Title,
          Description: descriptionData,
          // clients: clients,
          // dtStart: addHours(8,data.dtStart),
          // dtEnd: addHours(8, data.dtEnd),
          DtStart: formattedStartDate,
          DtEnd: formattedEndDate,
          HourStart: hourStartDate,
          HourEnd: hourEndDate,
          EventTypeId: data.EventTypeId,
          IsActive: true
        };
        if(newTask.EventId === "") {
          jwtAxios.post("events/register", newTask).then((res) => { 
            if (res.status === 200) 
            dispatch(fetchSuccess)
            dispatch(showMessage("evento cadastrado com sucesso!"))
           })
        }
        else {
          jwtAxios.post("events/update", newTask).then((res) => { 
            if (res.status === 200) 
            dispatch(fetchSuccess)
            dispatch(showMessage("evento atualizado com sucesso!"))
           })
        }

        resetForm();
        setSubmitting(false);
      }}
    >
      {({ isSubmitting, values, errors, touched }) => (
        <Form
          style={{
            width: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          {console.log(errors, "errors")}
          <div>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <AppTextField
                sx={{
                  width: "100%",
                  fontWeight: Fonts.LIGHT,
                  marginBottom: 5,
                }}
                variant="outlined"
                label={<IntlMessages id="titulo" />}
                placeholder="teste"
                name="Title"
              />
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={4}>
                  <DesktopDatePicker
                    disablePast
                    label="Data de Início"
                    inputFormat="dd/MM/yyyy"
                    value={values?.DtStart}
                    onChange={handleDtStartChange}
                    renderInput={(params) => <TextField {...params}
                      
                      name="DtStart"
                      style={{
                        width: "100%",
                        fontWeight: Fonts.LIGHT,
                        marginBottom: 3,
                      }} />}
                  />
                </Grid>



                <Grid item xs={3} >
                  <TimePicker
                    label="Hora de Início"
                    value={hourStart}
                    onChange={handleHourStartChange}
                    renderInput={(params) => <TextField {...params}
                      style={{
                        width: "100%",
                        fontWeight: Fonts.LIGHT,
                        marginBottom: 3,
                      }} />}
                  />
                </Grid>
              </Grid>
              {/* <Box mb={5}>
                <AppTextField
                  sx={{
                    width: "80%",
                    fontWeight: Fonts.LIGHT,
                    marginBottom: 3,
                  }}
                  variant="outlined"
                  label={<IntlMessages id="Participantes" />}
                  name="participants"
                />
                <FormControlLabel control={<Checkbox onClick={() => setOptionalVisibility(!optionalVisibility)} />} label="Opcionais" style={{ padding: 10 }} />
              </Box> */}
              <Box mb={5}>
                <AppTextField
                  className={optionalVisibility ? "visible" : "notVisible"}
                  sx={{
                    width: "100% !important",
                    fontWeight: Fonts.LIGHT,
                    marginBottom: 3,
                  }}
                  variant="outlined"
                  label={<IntlMessages id="Participantes Opcionais" />}
                  name="optionalParticipants"
                />
              </Box>
              {/* <Box mb={5}>
                {clients.map((item) => console.log(item.userId, "item"))}
              <FormControl sx={{ m: 1, width: "100%"}}>
                    <InputLabel id="demo-multiple-chip-label">Clientes</InputLabel>
                    <Select
                      labelId="demo-multiple-chip-label"
                      id="demo-multiple-chip"
                      multiple
                      //value
                      value={clients.map((item) => item.name)}

                      onChange={handleMultiChange}
                      input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                      renderValue={(selected) => {
                        console.log(selected, "selected");
                        
                        return(
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          {selected.map((value) => (
                            <Chip key={value} label={value} />
                          ))}
                        </Box>
                        )
                      }}
                      MenuProps={MenuProps}
                    >
                      {clients.map((client) => (
                        <MenuItem
                          key={client.userId}
                          value={client.userId}
                          style={getStyles("name", personName, theme)}
                        >
                          {client.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
              </Box> */}
              {/* <Box mb={5}>
              <FormControl sx={{ m: 1, width: "100%"}}>
                    <InputLabel id="demo-multiple-chip-label">Assessores</InputLabel>
                    <Select
                      labelId="demo-multiple-chip-label"
                      id="demo-multiple-chip"
                      multiple
                      //value
                      value={personName}
                      onChange={handleMultiChange}
                      input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                      renderValue={(selected) => {
                        console.log(selected)
                        
                      return (
                          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          {selected.map((value) => (
                            <Chip key={value} label={value} />
                          ))}
                        </Box>
                      )
                      }}
                      MenuProps={MenuProps}
                    >
                      {clientsNames.map((name) => (
                        <MenuItem
                          key={name}
                          value={name}
                          style={getStyles(name, personName, theme)}
                        >
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
              </Box> */}
              <Box mb={5}>

                <CKEditor

                  editor={ClassicEditor}
                  data={selectedEvent.desc? selectedEvent.desc: ""}
                  onReady={editor => {
                    // You can store the "editor" and use when it is needed.
                    console.log('Editor is ready to use!', editor);
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                     handleDescriptionChange(data);
                  }}
                  onBlur={(event, editor) => {
                    console.log('Blur.', editor);
                  }}
                  onFocus={(event, editor) => {
                    console.log('Focus.', editor);
                  }}

                  onInit={(editor) => {
                    // You can store the "editor" and use when it is needed.
                    // console.log("Editor is ready to use!", editor);
                    editor.editing.view.change((writer) => {
                      writer.setStyle(
                        "height",
                        "500px",
                        editor.editing.view.document.getRoot()
                      );
                    });
                  }}

                />
              </Box>


              <Box mb={5}>
                <AppGridContainer spacing={3}>
                  <Grid item xs={12} sm={6} md={3}>
                    <FormControl
                      sx={{
                        width: "100%",
                      }}
                      variant="outlined"
                    >
                      <InputLabel
                        id="demo-simple-select-outlined-label"
                      >
                        <IntlMessages id="Tipo de Evento" />
                      </InputLabel>
                      <Select label="EventType" name="EventTypeId">
                        {eventsTypes.map((event) => {
                          return (
                            <MenuItem
                              value={event.eventTypeId}
                              key={event.eventTypeId}
                              sx={{
                                cursor: "pointer",
                              }}
                            >
                              {event.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  {/* <Grid item xs={12} sm={6} md={3}>
                <AppTextField
                  sx={{
                    width: "100%",
                    fontWeight: Fonts.LIGHT,
                    marginBottom: 3,
                  }}
                  variant="outlined"
                  label={<IntlMessages id="telefone" />}
                  name="phoneNumber"
                />
              </Grid> */}
                  <Grid item xs={12} sm={6} md={3}>
                    <AppTextField
                      sx={{
                        width: "100%",
                        fontWeight: Fonts.LIGHT,
                        marginBottom: 3,
                      }}
                      variant="outlined"
                      label={<IntlMessages id="Place" />}
                      name="Place"
                    />
                  </Grid>


                  <Grid container item xs={12} spacing={2}>
                    <Grid item xs={4} >
                      <DesktopDatePicker
                        label="Data Fim"
                        disablePast
                        inputFormat="dd/MM/yyyy"
                        value={values.DtEnd}
                        onChange={handleDtEndChange}
                        renderInput={(params) => <TextField {...params}
                          name="DtEnd"
                          style={{
                            width: "100%",
                            fontWeight: Fonts.LIGHT,
                            marginBottom: 3,
                          }} />}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TimePicker
                        label="Hora Fim"
                        value={hourEnd}
                        onChange={handleHourEndChange}
                        renderInput={(params) => <TextField {...params}
                          style={{
                            width: "100%",
                            fontWeight: Fonts.LIGHT,
                            marginBottom: 3,
                          }} />}
                      />
                    </Grid>
                  </Grid>

                </AppGridContainer>
              </Box>



              <StyledDivider />
            </LocalizationProvider>
          </div>
          <div style={{ textAlign: "right" }}>
            <Button
              sx={{
                position: "relative",
                minWidth: 100,
              }}
              color="primary"
              variant="outlined"
              disabled={isSubmitting}
              type="submit"
            >
              <IntlMessages id="common.save" />
            </Button>
          </div>
        <AppInfoView/>
        </Form>
      )}
    </Formik>

  );
};
export default AddTaskForm;
