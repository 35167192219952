import React from "react";
import { Fonts } from "../../../../shared/constants/AppEnums";
import { BalanceCoins, customerRange } from "../../mock";
import { AppTableContainer } from "@crema";
import { Table, TableHead, TableBody, TableCell, TableRow, Box,  TableContainer } from "@mui/material";
import { CustomersRangePanelData } from "../NotificationsPanel";
import IntlMessages from "@crema/utility/IntlMessages";
interface CustomersRangeInfoProps {
  customersRangeInfo: CustomersRangePanelData[],
  totalValues: {
    totalValue: number,
    totalQtd: number,
  }
}

const CustomersRangeInfo: React.FC<CustomersRangeInfoProps> = ({customersRangeInfo, totalValues}) => {
  return (
    <TableContainer sx={{ 
       "&::-webkit-scrollbar": {
          width: 8
        },                
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "primary.main",          
          borderRadius: 2
        },
      maxHeight: 405 
      }}>
      <Table className="table"  sx={{mt:10, mb: 7}}>
         <TableHead>
          <TableRow
            sx={{
              position: "relative",
              "& .tableCell": {
                fontSize: 13,
                padding: 2,
                paddingTop: 0,
                fontWeight: Fonts.BOLD,
                whiteSpace: "nowrap",
                "&:first-of-type": {
                  paddingLeft: 5,
                },
                "&:last-of-type": {
                  paddingRight: 5,
                },
              },
            }}
          >
            <TableCell align="left" className="tableCell">
             <IntlMessages id="range" />
            </TableCell>
            <TableCell align="left" className="tableCell">
              Qtd
            </TableCell>
            <TableCell align="left" className="tableCell">
              %
            </TableCell>
            <TableCell align="left" className="tableCell">
            <IntlMessages id="value" /> (R$)
            </TableCell>
            <TableCell align="left" className="tableCell">
              %
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {customersRangeInfo.map((data) => (
            <TableRow
              key={data.UserId}
              className="item-hover"
              sx={{
                "& .tableCell": {
                  fontSize: 13,
                  padding: "12px 8px",
                  "&:first-of-type": {
                    paddingLeft: 5,
                  },
                  "&:last-of-type": {
                    paddingRight: 5,
                  },
                },                
              }}
              hover
            >
              <TableCell className="tableCell">
                <Box display="flex" alignItems="center">
                  <Box fontWeight={Fonts.MEDIUM}>{data.InvestorRangeName? data.InvestorRangeName : "-"}</Box>
                </Box>
              </TableCell>
              <TableCell className="tableCell">
                <Box>{data.Qtd? Intl.NumberFormat('pt-BR').format(Number(data.Qtd)): "-"}</Box>
              </TableCell>
              <TableCell className="tableCell">
                <Box>{
                      //String((data.Qtd * 100) / totalValues.totalQtd ).replace(".", ",").substring(0,4)
                      Intl.NumberFormat('pt-BR', {style: 'percent',minimumFractionDigits: 2}).format(Number(data.Qtd / totalValues.totalQtd ))
                    } </Box>
              </TableCell>
              <TableCell className="tableCell">
                <Box>{
                    data.NetWorth? Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(Number(data.NetWorth)): "-"
                  }</Box>
              </TableCell>
              <TableCell className="tableCell">
                <Box> {
                    //data.NetWorth? String((data.NetWorth * 100) / totalValues.totalValue).replace(".", ",").substring(0,4) : "-"
                    data.NetWorth? Intl.NumberFormat('pt-BR', {style: 'percent',minimumFractionDigits: 2}).format(Number(data.NetWorth/ totalValues.totalValue )) : "-"
                } </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CustomersRangeInfo;
