import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/pt-br';
import { StyledCalendar } from "../calandar.style";
import Box from "@mui/material/Box";
import AddNewTask from "pages/admin/events";
import AppDialog from "@crema/core/AppDialog";
import jwtAxios from "@crema/services/auth/jwt-auth";
import { useAuthUser } from "@crema/utility/AuthHooks";
import CloseIcon from '@mui/icons-material/Close';
import { Button, Dialog, IconButton, Toolbar, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { fetchSuccess, showEventConfirmation, showMessage } from "redux/actions";
import { AppInfoView } from "@crema";
import AppEventConfirmationView from "@crema/core/AppEventConfirmationView";

interface EventsData {
  Title: string;
  Desc: string;
  End: Date;
  Start: Date;
  Email: string;
  HourEnd:string;
  HourStart: string;
  Place: string;
  EventId: string;
  EventTypeId: string;
  PhoneNumber: string;
  ScheduleId: string;
  TenantId: string;
  UserId: string;
  IsActive: boolean;
}
const localizer = momentLocalizer(moment);

const Selectable = () => {
  const [eventsData, setEventsData] = useState<EventsData[]>([]);
 const dispatch = useDispatch();
  const { user } = useAuthUser();


  useEffect(() => {
    jwtAxios.post("events/getbyfilter",  { ParamSearch: { UserId: user?.userId }}).then((res) => {

      let events: EventsData[] = [];
      res.data.List.map((item: any) => {
        let eventsItem: EventsData = {
          Title: "",
          End: new Date(0,0,0,8,0,0),
          Start: new Date(0,0,0,8,0,0),
          Desc: "",
          Email: "",
          IsActive: true,
          Place: "",
          HourEnd:"",
          HourStart:"",
          EventId: "",
          EventTypeId: "",
          PhoneNumber: "",
          ScheduleId: "",
          TenantId: "",
          UserId: "",
        }
        eventsItem.Start = new Date(item.DtStart);
        eventsItem.End = new Date(item.DtEnd);
        eventsItem.HourStart = item.HourStart;
        eventsItem.HourEnd = item.DtEnd;
        eventsItem.Title = item.Title;
        eventsItem.Desc = item.Description;
        eventsItem.Email = item.Email;
        eventsItem.Place = item.Place;
        eventsItem.EventId = item.EventId;
        eventsItem.EventTypeId = item.EventTypeId;
        eventsItem.PhoneNumber = item.PhoneNumber;
        eventsItem.ScheduleId = item.ScheduleId;
        eventsItem.TenantId = item.TenantId;
        eventsItem.UserId = item.UserId;
        eventsItem.IsActive = item.IsActive;

        events.push(eventsItem);
      })
      dispatch(fetchSuccess());
      // dispatch(
      //   showEventConfirmation('Qual o Status do evento: Teste?')
      // )
      setEventsData(events);
    })
  }, [])
  const [selected, setSelected] = useState();
  const [event, setEvent] = useState<any>();

  const [modalView, setModalView] = useState<boolean>(false);
  const handleEventClick = (event) => {
    setModalView(true);

  };
  return (
    <div className="app-calendar app-cul-calendar">
      <StyledCalendar
        selectable
        events={eventsData}
        localizer={localizer}
        defaultView="month"
        scrollToTime={new Date(1970, 1, 1, 6)}
        defaultDate={new Date()}
        selected={selected}
        onSelectEvent={(event) => {
          setEvent(event);
          setModalView(true);
        }}
        onSelectSlot={(event) => {
          setEvent(event);
          setModalView(true);
        }}
        messages={{
          next: "Próximo",
          previous: "Anterior",
          today: "Hoje",
          month: "Mês",
          week: "Semana",
          day: "Dia"
        }}
      />
      <Dialog
        open={modalView}
        onClose={() => setModalView(false)}
        title="test"
        PaperProps={{
          sx: {
            minHeight: '80vh',
            maxHeight: '80vh',
            minWidth: '80vh',
            maxWidth: '80vh',
          }
        }}
      >
        <Toolbar>
       

          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Agenda
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => setModalView(false)}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      
        {event && (
            <AddNewTask selectedEvent={event} />
        )}
      </Dialog>
      <AppInfoView />
    </div>
  );
};

export default Selectable;
