import React from "react";

const Dashboard = React.lazy(() => import("../dashboard"));
const Page2 = React.lazy(() => import("./Page2"));
export const samplePagesConfigs = [
  {
    path: "/dashboard",
    element: <Dashboard />,
  },
  {
    path: "/sample/page-2",
    element: <Page2 />,
  },
];
