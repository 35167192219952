import { blue, orange } from "@mui/material/colors";
import { PaletteColorOptions } from "@mui/material";

export const studentRankings: any = [
    {
      id: 1,
      name: "Conta Corrente",
      Qtd: "-",
      QtdPercent: "-",
      Value: "15.234.423,80",
      ValuePercente: "1,0%",
    },
    {
      id: 2,
      name: "Fundos",
      Qtd: "8.804",
      QtdPercent: "22,3%",
      Value: "1.432.304.753,89",
      ValuePercente: "51,2%",
    },
    {
      id: 3,
      name: "Renda Fixa",
      Qtd: "2.620",
      QtdPercent: "28,1%",
      Value: "1.435.698.828,80",
      ValuePercente: "28,3%",
    },
    {
      id: 4,
      name: "Renda Variável",
      Qtd: "4.570",
      QtdPercent: "28.1%",
      Value: "645.432.234,18",
      ValuePercente: "11,1%",
    },
    {
      id: 5,
      name: "Previdência",
      Qtd: "4.414",
      QtdPercent: "2,5%",
      Value: "546.423.432,43",
      ValuePercente: "8,8%",
    },
    {
        id: 6,
        name: "Derivativos",
        Qtd: "500",
      QtdPercent: "1.0%",
      Value: "-1.528.337,37",
      ValuePercente: "0,0%",
      },
      {
        id: 7,
        name: "Valor em Trânsito",
        Qtd: "4.663",
      QtdPercent: "16,4%",
      Value: "-10.866.962,27",
      ValuePercente: "-0,2%",
      },
  ]
  export const Contribuition: any = [
    {
      id: 1,
      name: "2002",
      aporte: "201.432.42,29",
      retirada: "-69.432.657,77",
    },
    {
      id: 2,
      name: "Jul",
      aporte: "647.534.475,29",
      retirada: "-32.234.132,77",
    },
    {
      id: 3,
      name: "Aug",
      aporte: "754.131.344,29",
      retirada: "-66.555.332,77",
    },
    {
      id: 4,
      name: "Sep",
      aporte: "423.123.646,29",
      retirada: "-54.345.243,77",
    },
    {
      id: 5,
      name: "Oct",
      aporte: "101.209.475,29",
      retirada: "-43.445.180,77",
    },
    {
        id: 6,
        name: "Nov",
        aporte: "524.423.423,29",
        retirada: "-423.423.42,77",
      },
      {
        id: 7,
        name: "Dec",
        aporte: "48.606.535,63",
        retirada: "-17.604.143,27",
      },
      {
        id: 7,
        name: "2023",
        aporte: "85.818.481,63",
        retirada: "-60.305.467,52",
      },
      {
        id: 7,
        name: "jan",
        aporte: "85.818.481,63",
        retirada: "-60.305.467,52",
      },
  ]
  export const customerRange: any = [
    {
      id: 1,
      name: "Acima de 100MM",
      Qtd: "43",
      QtdPercent: "1,4%",
      Value: "560.437.278,97",
      ValuePercente: "46,2%",
    },
    {
      id: 2,
      name: "Entre 50MM e 100MM",
      Qtd: "233",
      QtdPercent: "1,2%",
      Value: "75.722.055,59",
      ValuePercente: "51,2%",
    },
    {
      id: 3,
      name: "Entre 30MM e 50MM",
      Qtd: "25",
      QtdPercent: "1,2%",
      Value: "43.365.788,66",
      ValuePercente: "8,1%",
    },
    {
      id: 4,
      name: "Entre 10MM e 30MM",
      Qtd: "106",
      QtdPercent: "5,2%",
      Value: "20.433.810,31",
      ValuePercente: "15,3%",
    },
    {
      id: 5,
      name: "Entre 5MM e 10MM",
      Qtd: "123",
      QtdPercent: "6,1%",
      Value: "8.708.326,28",
      ValuePercente: "7,2%",
    },
    {
        id: 6,
        name: "Entre 3MM e 5MM",
        Qtd: "98",
      QtdPercent: "4.82%",
      Value: "3.266.958,63",
      ValuePercente: "3,0%",
      },
      {
        id: 7,
        name: "Entre 1MM e 3MM",
        Qtd: "285",
      QtdPercent: "14,1%",
      Value: "2.285.303,27",
      ValuePercente: "4,1%",
      },
      {
        id: 8,
        name: "Entre 500K e 1MM",
        Qtd: "148",
      QtdPercent: "7,3%",
      Value: "578.696,13",
      ValuePercente: "0,9%",
      },
      {
        id: 9,
        name: "Ate 50K",
        Qtd: "1.190",
      QtdPercent: "58,7%",
      Value: "8.493.277,43",
      ValuePercente: "0,7%",
      },
  ]
export const welcomeCardData = [
    {
        id: 1,
        type: "Emails",
        counts: 42,
        icon: "HiOutlineMailOpen",
    },
    {
        id: 2,
        type: "Invoices",
        counts: 12,
        icon: "CgFileDocument",
    },   
    {
        id: 4,
        type: "Suitability",
        counts: 5,
        icon: "AiOutlineWarning",
    },
];

export interface StudentRankingData {
    id: number;
    name: string;
    profile_pic: string;
    courseId: number;
    courseName: string;
    totalGrade: number;
    ranking: number;
    category: string;
  }
  
export const contactPainelItemData: TotalBalanceData = {
    balance: "33692.00",
    coins: [
        { id: 32423, name: "Telefone", value: 9 },
        { id: 3333, name: "Mensageria", value: 76 },
        { id: 44334, name: "Email", value: 1567 },
    ],
};
export const meetingsPainelData: TotalBalanceData = {
    balance: "33692.00",
    coins: [
        { id: 32423, name: "meetings.ended", value: 9 },
        { id: 3333, name: "meetings.scheduled", value: 76 },
    ],
};
export const accountData: TotalBalanceData = {
    balance: "33692.00",
    title: "currentAccount.title",
    coins: [
        { id: 32423, name: "currentAccount.client", value: 9 },
        { id: 3333, name: "currentAccount.value", value: 76 },
    ],
};

export interface CoinGraphData {
    bitcoin: {
        yearlyData: { month: string; amount: number }[];
        monthlyData: { date: string; amount: number }[];
        weeklyData: { day: string; amount: number }[];
        dailyData: { time: string; amount: number }[];
    };
    litecoin: {
        yearlyData: { month: string; amount: number }[];
        monthlyData: { date: string; amount: number }[];
        weeklyData: { day: string; amount: number }[];
        dailyData: { time: string; amount: number }[];
    };
    ripple: {
        yearlyData: { month: string; amount: number }[];
        monthlyData: { date: string; amount: number }[];
        weeklyData: { day: string; amount: number }[];
        dailyData: { time: string; amount: number }[];
    };
}

export interface BuySellDataProps {
    value: string;
    price: string;
    amount: string;
}

export interface BuySellProps {
    buyData: BuySellDataProps;
    sellData: BuySellDataProps;
}
export interface CoinData {
    price: string;
    increment: number;
}

export interface CoinsDataProps {
    bitcoin: CoinData;
    etherium: CoinData;
    liteCoin: CoinData;
    ripple: CoinData;
}

export interface MarketGraphData {
    month: string;
    medium: number;
    low: number;
    high: number;
    amt: number;
}

export interface NewsData {
    id: number;
    news: string;
    created: string;
    image: string;
    by: string;
}

export interface PopularCoinsData {
    id: number;
    name: string;
    shortName: string;
    marketCap: string;
    volume: string;
    h: string;
    image: string;
    color: PaletteColorOptions | string;
}

export interface BalanceCoins {
    id: number;
    name: string;
    value: number;
  }

export interface TotalBalanceData {
    balance : string;
    title?: string;
    coins: BalanceCoins[];
}
export interface TotalPL {
    balance: string;
    coins: BalanceCoins[];
}

export interface BtcChartData {
    id: number;
    name: string;
    value: number;
    color: string;
}
export interface Crypto {
    coinGraphData: CoinGraphData;
    buySell: BuySellProps;
    coinsData: CoinsDataProps;
    marketGraphData: MarketGraphData[];
    newsData: NewsData[];
    popularCoins: PopularCoinsData[];
    totalBalanceData: TotalBalanceData;
    totalPL: TotalPL;
    btcChartData: BtcChartData[];
}
const cryptoData: Crypto = {
    coinGraphData: {
        bitcoin: {
            yearlyData: [
                { month: "Jan", amount: 6000 },
                { month: "Feb", amount: 4800 },
                { month: "Mar", amount: 6500 },
                { month: "Apr", amount: 6000 },
                { month: "May", amount: 7800 },
                { month: "Jun", amount: 9000 },
                { month: "Jul", amount: 6000 },
                { month: "Aug", amount: 7000 },
                { month: "Sep", amount: 3700 },
                { month: "Oct", amount: 7800 },
                { month: "Nov", amount: 5800 },
                { month: "Dec", amount: 6500 },
            ],
            monthlyData: [
                { date: "1", amount: 6000 },
                { date: "2", amount: 4800 },
                { date: "3", amount: 6500 },
                { date: "4", amount: 6000 },
                { date: "5", amount: 7800 },
                { date: "6", amount: 9000 },
                { date: "7", amount: 6000 },
                { date: "8", amount: 7000 },
                { date: "9", amount: 3700 },
                { date: "10", amount: 7800 },
                { date: "11", amount: 5800 },
                { date: "12", amount: 6500 },
                { date: "13", amount: 7200 },
                { date: "14", amount: 6500 },
                { date: "15", amount: 2344 },
                { date: "16", amount: 4243 },
                { date: "17", amount: 5343 },
                { date: "18", amount: 2435 },
                { date: "19", amount: 4322 },
                { date: "20", amount: 2545 },
                { date: "21", amount: 3234 },
                { date: "22", amount: 4233 },
                { date: "23", amount: 2444 },
                { date: "24", amount: 6543 },
                { date: "25", amount: 4223 },
                { date: "26", amount: 4323 },
                { date: "27", amount: 2345 },
                { date: "28", amount: 2345 },
                { date: "29", amount: 5676 },
                { date: "30", amount: 2345 },
            ],
            weeklyData: [
                { day: "Monday", amount: 6000 },
                { day: "Tuesday", amount: 4800 },
                { day: "Wednesday", amount: 6500 },
                { day: "Thrusday", amount: 6000 },
                { day: "Friday", amount: 7800 },
                { day: "Saturday", amount: 9000 },
                { day: "Sunday", amount: 6000 },
            ],
            dailyData: [
                { time: "00:00", amount: 2444 },
                { time: "01:00", amount: 6000 },
                { time: "02:00", amount: 4800 },
                { time: "03:00", amount: 6500 },
                { time: "04:00", amount: 6000 },
                { time: "05:00", amount: 7800 },
                { time: "06:00", amount: 9000 },
                { time: "07:00", amount: 6000 },
                { time: "08:00", amount: 7000 },
                { time: "09:00", amount: 3700 },
                { time: "10:00", amount: 7800 },
                { time: "11:00", amount: 5800 },
                { time: "12:00", amount: 6500 },
                { time: "13:00", amount: 7200 },
                { time: "14:00", amount: 6500 },
                { time: "15:00", amount: 2344 },
                { time: "16:00", amount: 4243 },
                { time: "17:00", amount: 5343 },
                { time: "18:00", amount: 2435 },
                { time: "19:00", amount: 4322 },
                { time: "20:00", amount: 2545 },
                { time: "21:00", amount: 3234 },
                { time: "22:00", amount: 4233 },
                { time: "23:00", amount: 2444 },
            ],
        },
        litecoin: {
            yearlyData: [
                { month: "Jan", amount: 5600 },
                { month: "Feb", amount: 4400 },
                { month: "Mar", amount: 6000 },
                { month: "Apr", amount: 4500 },
                { month: "May", amount: 7000 },
                { month: "Jun", amount: 6400 },
                { month: "Jul", amount: 8000 },
                { month: "Aug", amount: 6500 },
                { month: "Sep", amount: 7000 },
                { month: "Oct", amount: 5800 },
                { month: "Nov", amount: 6200 },
                { month: "Dec", amount: 5000 },
            ],
            monthlyData: [
                { date: "1", amount: 5600 },
                { date: "2", amount: 7000 },
                { date: "3", amount: 6400 },
                { date: "4", amount: 4500 },
                { date: "5", amount: 5500 },
                { date: "6", amount: 5900 },
                { date: "7", amount: 7500 },
                { date: "8", amount: 6500 },
                { date: "9", amount: 5400 },
                { date: "10", amount: 5000 },
                { date: "11", amount: 4400 },
                { date: "12", amount: 4900 },
                { date: "13", amount: 5500 },
                { date: "14", amount: 6300 },
                { date: "15", amount: 3944 },
                { date: "16", amount: 4243 },
                { date: "17", amount: 5043 },
                { date: "18", amount: 6935 },
                { date: "19", amount: 5622 },
                { date: "20", amount: 7645 },
                { date: "21", amount: 4034 },
                { date: "22", amount: 5633 },
                { date: "23", amount: 3944 },
                { date: "24", amount: 4543 },
                { date: "25", amount: 4023 },
                { date: "26", amount: 3623 },
                { date: "27", amount: 5045 },
                { date: "28", amount: 3645 },
                { date: "29", amount: 4576 },
                { date: "30", amount: 2945 },
            ],
            weeklyData: [
                { day: "Monday", amount: 6500 },
                { day: "Tuesday", amount: 5200 },
                { day: "Wednesday", amount: 7800 },
                { day: "Thrusday", amount: 3900 },
                { day: "Friday", amount: 5200 },
                { day: "Saturday", amount: 2600 },
                { day: "Sunday", amount: 7800 },
            ],
            dailyData: [
                { time: "00:00", amount: 4500 },
                { time: "01:00", amount: 6000 },
                { time: "02:00", amount: 3500 },
                { time: "03:00", amount: 4300 },
                { time: "04:00", amount: 5500 },
                { time: "05:00", amount: 6300 },
                { time: "06:00", amount: 7000 },
                { time: "07:00", amount: 6000 },
                { time: "08:00", amount: 5200 },
                { time: "09:00", amount: 3900 },
                { time: "10:00", amount: 4600 },
                { time: "11:00", amount: 4000 },
                { time: "12:00", amount: 4800 },
                { time: "13:00", amount: 6400 },
                { time: "14:00", amount: 5200 },
                { time: "15:00", amount: 3944 },
                { time: "16:00", amount: 2543 },
                { time: "17:00", amount: 4043 },
                { time: "18:00", amount: 4735 },
                { time: "19:00", amount: 3822 },
                { time: "20:00", amount: 5245 },
                { time: "21:00", amount: 6534 },
                { time: "22:00", amount: 5533 },
                { time: "23:00", amount: 5044 },
            ],
        },
        ripple: {
            yearlyData: [
                { month: "Jan", amount: 7600 },
                { month: "Feb", amount: 6200 },
                { month: "Mar", amount: 6800 },
                { month: "Apr", amount: 5000 },
                { month: "May", amount: 4200 },
                { month: "Jun", amount: 5600 },
                { month: "Jul", amount: 6800 },
                { month: "Aug", amount: 8100 },
                { month: "Sep", amount: 5700 },
                { month: "Oct", amount: 4500 },
                { month: "Nov", amount: 3700 },
                { month: "Dec", amount: 4700 },
            ],
            monthlyData: [
                { date: "1", amount: 4300 },
                { date: "2", amount: 3600 },
                { date: "3", amount: 4500 },
                { date: "4", amount: 5200 },
                { date: "5", amount: 4800 },
                { date: "6", amount: 5600 },
                { date: "7", amount: 6700 },
                { date: "8", amount: 5400 },
                { date: "9", amount: 5000 },
                { date: "10", amount: 6300 },
                { date: "11", amount: 6700 },
                { date: "12", amount: 7800 },
                { date: "13", amount: 7300 },
                { date: "14", amount: 6200 },
                { date: "15", amount: 4644 },
                { date: "16", amount: 5043 },
                { date: "17", amount: 4243 },
                { date: "18", amount: 4635 },
                { date: "19", amount: 3222 },
                { date: "20", amount: 4145 },
                { date: "21", amount: 3734 },
                { date: "22", amount: 4233 },
                { date: "23", amount: 3144 },
                { date: "24", amount: 3543 },
                { date: "25", amount: 3023 },
                { date: "26", amount: 4023 },
                { date: "27", amount: 4245 },
                { date: "28", amount: 3145 },
                { date: "29", amount: 3576 },
                { date: "30", amount: 2345 },
            ],
            weeklyData: [
                { day: "Monday", amount: 3400 },
                { day: "Tuesday", amount: 4800 },
                { day: "Wednesday", amount: 3700 },
                { day: "Thrusday", amount: 5600 },
                { day: "Friday", amount: 5100 },
                { day: "Saturday", amount: 7200 },
                { day: "Sunday", amount: 6300 },
            ],
            dailyData: [
                { time: "00:00", amount: 2600 },
                { time: "01:00", amount: 3300 },
                { time: "02:00", amount: 2800 },
                { time: "03:00", amount: 3200 },
                { time: "04:00", amount: 4000 },
                { time: "05:00", amount: 3400 },
                { time: "06:00", amount: 3100 },
                { time: "07:00", amount: 4500 },
                { time: "08:00", amount: 4900 },
                { time: "09:00", amount: 4200 },
                { time: "10:00", amount: 5300 },
                { time: "11:00", amount: 5900 },
                { time: "12:00", amount: 4800 },
                { time: "13:00", amount: 6800 },
                { time: "14:00", amount: 6100 },
                { time: "15:00", amount: 7844 },
                { time: "16:00", amount: 7143 },
                { time: "17:00", amount: 4043 },
                { time: "18:00", amount: 4835 },
                { time: "19:00", amount: 4322 },
                { time: "20:00", amount: 3645 },
                { time: "21:00", amount: 4934 },
                { time: "22:00", amount: 5933 },
                { time: "23:00", amount: 3944 },
            ],
        },
    },
    buySell: {
        buyData: {
            value: "122",
            price: "$7223.9",
            amount: "$87323.8",
        },
        sellData: {
            value: "34",
            price: "$723.9",
            amount: "$7632.8",
        },
    },
    coinsData: {
        bitcoin: {
            price: "7289.75",
            increment: 0.8,
        },
        etherium: {
            price: "170.720",
            increment: 0.76,
        },
        liteCoin: {
            price: "65.1200",
            increment: -0.4,
        },
        ripple: {
            price: "0.2544",
            increment: 0.08,
        },
    },
    marketGraphData: [
        { month: "Jan", medium: 3000, low: 4000, high: 0, amt: 2400 },
        { month: "Feb", medium: 1000, low: 4000, high: 0, amt: 2210 },
        { month: "Mar", medium: 4000, low: 4000, high: 1200, amt: 2290 },
        { month: "Apr", medium: 3700, low: 4000, high: 0, amt: 2000 },
        { month: "May", medium: 1890, low: 4000, high: 0, amt: 2181 },
        { month: "Jun", medium: 0, low: 1800, high: 0, amt: 2400 },
        { month: "Jul", medium: 0, low: 3500, high: 0, amt: 2400 },
        { month: "Aug", medium: 4000, low: 4000, high: 0, amt: 2400 },
    ],
    newsData: [
        {
            id: 1,
            news: "Power Ledger’s Jemma Green Is Fighting Climate Change by Helping Trade Neighbors Energy. Binance Coin Spikes Almost 20 Percent as...",
            created: "16 mins",
            image: "/assets/images/bitcoin1.png",
            by: "BTC EHTD USD",
        },
        {
            id: 2,
            news: "Binance Coin Spikes Almost 20 Percent as Bitcoin and Crypto Market Take a Break After Red Wave. Bitcoin with other major coins have dropped...",
            created: "45 mins",
            image: "/assets/images/bitcoin2.png",
            by: "BTC EHTD USD",
        },
        {
            id: 3,
            news: "Power Ledger’s Jemma Green Is Fighting Climate Change by Helping Trade Neighbors Energy. Binance Coin Spikes Almost 20 Percent as...",
            created: "1 Hrs",
            image: "/assets/images/bitcoin3.png",
            by: "BTC EHTD USD",
        },
    ],
    popularCoins: [
        {
            id: 1,
            name: "Bitcoin",
            shortName: "BTC",
            marketCap: "129,820,932",
            volume: "25,111,773",
            h: "2.50",
            image: "/assets/images/bitcoin.svg",
            color: orange[600],
        },
        {
            id: 2,
            name: "Ethereum",
            shortName: "ETH",
            marketCap: "24,909,820",
            volume: "12,344,434",
            h: "0.45",
            image: "/assets/images/etherium.svg",
            color: "black",
        },
        {
            id: 3,
            name: "Litecoin",
            shortName: "LTC",
            marketCap: "137,334,223",
            volume: "43,434,213",
            h: "3.43",
            image: "/assets/images/litcoin.svg",
            color: blue[400],
        },
        {
            id: 4,
            name: "Monero",
            shortName: "XMR",
            marketCap: "21,445,237",
            volume: "32,324,655",
            h: "0.93",
            image: "/assets/images/bitcoin.svg",
            color: orange[500],
        },
        {
            id: 5,
            name: "Dashcoin",
            shortName: "DASH",
            marketCap: "124,674,765",
            volume: "94,342,323",
            h: ".30",
            image: "/assets/images/bitcoin.svg",
            color: blue[600],
        },
    ],
    totalBalanceData: {
        balance: "33692.00",
        coins: [
            { id: 32423, name: "Bitcoin", value: 9.654 },
            { id: 3333, name: "Monero", value: 76.184 },
            { id: 44334, name: "Ripple", value: 1567.5 },
            { id: 54323, name: "Litecoin", value: 56.78 },
        ],
    },
    totalPL: {
        balance: "10.212.171.495,88",
        coins: [
            { id: 32423, name: "Bitcoin", value: 9.654 },
            { id: 3333, name: "Monero", value: 76.184 },
            { id: 44334, name: "Ripple", value: 1567.5 },
            { id: 54323, name: "Litecoin", value: 56.78 },
        ],
    },
    
    btcChartData: [
        {
            id: 1001,
            name: "GBP",
            value: 302,
            color: "#4299E1",
        },
        {
            id: 1002,
            name: "USD",
            value: 450,
            color: "#E53E3E",
        },
        {
            id: 1003,
            name: "CNY",
            value: 274,
            color: "#38B2AC",
        },
        {
            id: 1004,
            name: "EUR",
            value: 218,
            color: "#4C51BF",
        },
    ],
};
export default cryptoData;
