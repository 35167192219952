import React from "react";
import Box from "@mui/material/Box";
import { Fonts } from "../../../../shared/constants/AppEnums";
import { BalanceCoins } from "../../mock";
import { AppGridContainer } from "@crema";
import { Divider, Grid, Tooltip } from "@mui/material";
import EmailIcon from '@mui/icons-material/Email';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import MessageIcon from '@mui/icons-material/Message';
import { ContactsPanelData } from "../NotificationsPanel";
import IntlMessages from "@crema/utility/IntlMessages";

interface ContactsInfoProps {
  contactsInfo: ContactsPanelData[];
  
}

const ContactsInfo: React.FC<ContactsInfoProps> = ({contactsInfo}) => {
  return (
    <Box
      sx={{
        mx: -2,
        mb: { xl: 1 },
        padding: "10px",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          position: "relative",
          display: "flex",
          justifyContent: "space-between"
        }}
      >
        <Box
          component="p"
          sx={{
            fontSize: 14,
            color: "text.secondary",
            mb: 2,
            fontWeight: Fonts.SEMI_BOLD,
            width: 150
          }}
        >

        </Box>
        <Box sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          justifyContent: "space-between",
          marginBottom: "19px",
        }}
        >
          <Box >
            <Box
              component="h3"
              sx={{
                fontWeight: Fonts.MEDIUM,
                fontSize: 16,
                color: "primary.main",
              }}
            >
              <Tooltip placement="top" title="" arrow>
                <PhoneInTalkIcon />
              </Tooltip>
            </Box>
            <Box
              component="p"
              sx={{
                fontSize: 14,
                color: "text.secondary",
              }}
            >

              {/* Telefone */}
            </Box>
          </Box>

          <Box sx={{
            ml: 6,
          }}>
            <Box
              component="h3"
              sx={{
                fontWeight: Fonts.MEDIUM,
                fontSize: 16,
                color: "primary.main",
              }}
            >
              <Tooltip placement="top" title="" arrow>
                <MessageIcon />
              </Tooltip>
            </Box>
            <Box
              component="p"
              sx={{
                fontSize: 14,
                color: "text.secondary",
              }}
            >
              {/* Mensageria */}
            </Box>
          </Box>
          <Box sx={{
            ml: 6,
          }}>
            <Box
              component="h3"
              sx={{
                fontWeight: Fonts.MEDIUM,
                fontSize: 16,
                color: "primary.main",
              }}
            >
              <Tooltip placement="top" title="" arrow>
                <EmailIcon />
              </Tooltip>
            </Box>
            <Box
              component="p"
              sx={{
                fontSize: 14,
                color: "text.secondary",
              }}
            >
              {/* Emails */}
            </Box>
          </Box>
        </Box>

      </Box>
      <Box
        sx={{
          position: "relative",
          display: "flex"
        }}
      >
        <Box
          component="p"
          sx={{
            fontSize: 14,
            color: "text.secondary",
            mb: 2,
            fontWeight: Fonts.SEMI_BOLD,
            marginRight: 2.5,
            width: 150
          }}
        >
          <IntlMessages id="incoming"/>
        </Box>
        <Box sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          justifyContent: "space-between",
          marginBottom: "25px",
        }}
        >
          <Box >
            <Box
              component="h3"
              sx={{
                fontWeight: Fonts.MEDIUM,
                fontSize: 16,
                pr: 2,
                marginRight: 1,
                color: "primary.main",
              }}
            >
              -
            </Box>
            <Box
              component="p"
              sx={{
                fontSize: 14,
                color: "text.secondary",
              }}
            >
              {/* Telefone */}
            </Box>
          </Box>

          <Box sx={{
            ml: 6,
          }}>
            <Box
              component="h3"
              sx={{
                fontWeight: Fonts.MEDIUM,
                fontSize: 16,
                color: "primary.main",
                pr: 2,
              }}
            >
              -
            </Box>
            <Box
              component="p"
              sx={{
                fontSize: 14,
                color: "text.secondary",
              }}
            >
              {/* Mensageria */}
            </Box>
          </Box>
          <Box sx={{
            ml: 6,
          }}>
            <Box
              component="h3"
              sx={{
                fontWeight: Fonts.MEDIUM,
                fontSize: 16,
                color: "primary.main",
                marginLeft: 1,

              }}
            >
              -
            </Box>
            <Box
              component="p"
              sx={{
                fontSize: 14,
                color: "text.secondary",
              }}
            >
              {/* Emails */}
            </Box>
          </Box>
        </Box>

      </Box>
      <Box
        sx={{
          position: "relative",
          display: "flex"
        }}
      >
        <Box
          component="p"
          sx={{
            fontSize: 14,
            color: "text.secondary",
            mb: 2,
            fontWeight: Fonts.SEMI_BOLD,
            marginRight: 2.5,
            width: 150
          }}
        >
          <IntlMessages id="send" />
        </Box>
        <Box sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          justifyContent: "space-between",
          marginBottom: "25px",
        }}
        >
          <Box >
            <Box
              component="h3"
              sx={{
                fontWeight: Fonts.MEDIUM,
                fontSize: 16,
                pr: 2,
                marginRight: 1,
                color: "primary.main",
              }}
            >
              -
            </Box>
            <Box
              component="p"
              sx={{
                fontSize: 14,
                color: "text.secondary",
              }}
            >
              {/* Telefone */}
            </Box>
          </Box>

          <Box sx={{
            ml: 6,
          }}>
            <Box
              component="h3"
              sx={{
                fontWeight: Fonts.MEDIUM,
                fontSize: 16,
                color: "primary.main",
                pr: 2,
              }}
            >
              -
            </Box>
            <Box
              component="p"
              sx={{
                fontSize: 14,
                color: "text.secondary",
              }}
            >
              {/* Mensageria */}
            </Box>
          </Box>
          <Box sx={{
            ml: 6,
          }}>
            <Box
              component="h3"
              sx={{
                fontWeight: Fonts.MEDIUM,
                fontSize: 16,
                color: "primary.main",
                marginLeft: 1,

              }}
            >
              -
            </Box>
            <Box
              component="p"
              sx={{
                fontSize: 14,
                color: "text.secondary",
              }}
            >
              {/* Emails */}
            </Box>
          </Box>
        </Box>

      </Box>
      <Box
        sx={{
          position: "relative",
          display: "flex"
        }}
      >
        <Box
          component="p"
          sx={{
            fontSize: 14,
            color: "text.secondary",
            mb: 2,
            fontWeight: Fonts.SEMI_BOLD,
            marginRight: 2.5,
            width: 150
          }}
        >
          <IntlMessages id="now" />
        </Box>
        <Box sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          justifyContent: "space-between",
          marginBottom: "25px",
        }}
        >
          <Box >
            <Box
              component="h3"
              sx={{
                fontWeight: Fonts.MEDIUM,
                fontSize: 16,
                pr: 2,
                marginRight: 1,
                color: "primary.main",
              }}
            >
              -
            </Box>
            <Box
              component="p"
              sx={{
                fontSize: 14,
                color: "text.secondary",
              }}
            >
              {/* Telefone */}
            </Box>
          </Box>

          <Box sx={{
            ml: 6,
          }}>
            <Box
              component="h3"
              sx={{
                fontWeight: Fonts.MEDIUM,
                fontSize: 16,
                color: "primary.main",
                pr: 2,
              }}
            >
              -
            </Box>
            <Box
              component="p"
              sx={{
                fontSize: 14,
                color: "text.secondary",
              }}
            >
              {/* Mensageria */}
            </Box>
          </Box>
          <Box sx={{
            ml: 6,
          }}>
            <Box
              component="h3"
              sx={{
                fontWeight: Fonts.MEDIUM,
                fontSize: 16,
                color: "primary.main",
                marginLeft: 1,

              }}
            >
              -
            </Box>
            <Box
              component="p"
              sx={{
                fontSize: 14,
                color: "text.secondary",
              }}
            >
              {/* Emails */}
            </Box>
          </Box>
        </Box>
      </Box>
      
    </Box>
  );
};

export default ContactsInfo;
