import React, { useEffect, useState } from "react";
import { IconButton, Theme } from "@mui/material";
import List from "@mui/material/List";
import Button from "@mui/material/Button";
import AppScrollbar from "@crema/core/AppScrollbar";
import IntlMessages from "@crema/utility/IntlMessages";
import NotificationItem from "./NotificationItem";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useAuthUser } from "@crema/utility/AuthHooks";
import jwtAxios from "@crema/services/auth/jwt-auth";
import { useNavigate } from "react-router-dom";

interface NotificationData {
  notificationsId: string;
  notificationsTypeId: string;
  title: string;
  description: string;
}

const AppNotificationContent = ({
  onClose,
  sxStyle
}) => {

  const { user } = useAuthUser();
  const [notification, setNotification] = useState<NotificationData[]>([]);
  const navigate = useNavigate();


  useEffect(() => {
    initForm();
  }, [])

  async function initForm() {
    await jwtAxios.post("/notifications/getbyfilter", { UserId: user?.userId, isRead: false }).then((res) => {
      setNotification(res.data.list);
    })
  }
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: 280,
        height: "100%",
        ...sxStyle,
      }}
    >
      <Box
        sx={{
          padding: "5px 20px",
          display: "flex",
          alignItems: "center",
          borderBottom: 1,
          borderBottomColor: (theme) => theme.palette.divider,
          minHeight: { xs: 56, sm: 70 },
        }}
      >
        <Typography component="h3">
          <IntlMessages id="common.notifications" />({notification.length})
        </Typography>
        <IconButton
          sx={{
            height: 40,
            width: 40,
            marginLeft: "auto",
            color: "text.secondary",
          }}
          onClick={onClose}
          size="large"
        >
          <CancelOutlinedIcon />
        </IconButton>
      </Box>
      <AppScrollbar
        sx={{
          height: { xs: "calc(100% - 96px)", sm: "calc(100% - 110px)" },
        }}
      >
        <List sx={{ py: 0 }}>
          {notification.map((item) => (
            <NotificationItem key={item.notificationsId} item={item} />
          ))}
        </List>
      </AppScrollbar>
      <Button
        sx={{
          borderRadius: 0,
          width: "100%",
          textTransform: "capitalize",
          marginTop: "auto",
          height: 40,
        }}
        variant="contained"
        color="primary"
        onClick={() => navigate("/admin/notifications/list")}
      >
        <IntlMessages id="common.viewAll" />
      </Button>
    </Box>
  );
};

export default AppNotificationContent;
