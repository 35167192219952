import React from "react";
import Box from "@mui/material/Box";
import { Fonts } from "../../../../shared/constants/AppEnums";
import { BalanceCoins } from "../../mock";
import { AppGridContainer } from "@crema";
import { Grid, useMediaQuery } from "@mui/material";
import IntlMessages from "@crema/utility/IntlMessages";

interface CoinsInfoProps {
  coins: BalanceCoins[];
}

const CoinsInfo: React.FC<CoinsInfoProps> = ({ coins }) => {
  const matches = useMediaQuery('(max-width:1400px)');
  return (
    <Box
      sx={{
        mx: -2,
        mb: { xl: 1 },
        display: "flex",
        flexWrap: matches? "wrap": "nowrap",
        justifyContent: "space-between",
        
      }}
    >
       {coins.map((coin) => {
        return (
          <Box
            sx={{
              mt: { xl: 3 },
              px: 2,
            }}
            key={coin.id}
          >
        
            <Box
              component="p"
              sx={{
                fontSize: matches? 12: 14,
                color: "text.secondary",
              }}
            >
              <IntlMessages id={coin.name} /> 
            </Box>
            <Box
              component="h3"
              sx={{
                fontWeight: Fonts.BOLD,
                fontSize: matches? 12: 20,
                color: "primary.main",
              }}
            >
              -
              {/* {coin.value} */}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default CoinsInfo;
