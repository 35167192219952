import React from "react";

import { useSelector } from "react-redux";
import AppMessageView from "@crema/core/AppMessageView";
import AppLoader from "@crema/core/AppLoader";
import { AppState } from "../../../redux/store";
import AppEventConfirmationView from "../AppEventConfirmationView";


// import Button from "@mui/material/Button";
// import Dialog from "@mui/material/Dialog";
// import DialogActions from "@mui/material/DialogActions";
// import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
// import DialogTitle from "@mui/material/DialogTitle";


// import Alert from '@mui/material/Alert';
// import AlertTitle from '@mui/material/AlertTitle';
// import Stack from '@mui/material/Stack';
// import { Box } from "@mui/material";


const AppInfoView = () => {
  const { error, loading, message, eventInfo } = useSelector<AppState, AppState["common"]>(
    ({ common }) => common
  );
  // const [open, setOpen] = React.useState(false);

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  const handleEventClick = (event: React.MouseEvent<HTMLElement>) => {
  };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  const showMessage = () =>  {
    return <AppMessageView variant="success" message={message.toString()} />;
    // return (
    //   <div>
    //     <Dialog
    //       open={true}
    //       onClose={handleClose}
    //       aria-labelledby="alert-dialog-title"
    //       aria-describedby="alert-dialog-description"

    //     >
    //       <Stack sx={{ width: '100%' }} spacing={2}>
    //         <Alert variant='filled' severity='success'>
    //           <AlertTitle>Success</AlertTitle>
              
    //           <Box sx={{ width: '100%',
    //                       height: '75px',
    //                      display: "flex",
    //                      alignItems: "center",
    //                      fontWeight: '2.4rem',
    //                      }}>
    //           record inserted successfully, would you like to return to the listing page?
    //           </Box>
    //           <Box sx={{ width: '100%',
    //                      display: "flex",
    //                      alignItems: "center",
    //                      flexDirection: "row-reverse"}}>
    //                         <Button sx={{
    //                     position: "relative",
    //                     minWidth: 100,
    //                     ml: 2.5,
    //                 }} onClick={handleClose} autoFocus  variant='contained' size='small'>Agree</Button>
    //             <Button  sx={{
    //                     position: "relative",
    //                     minWidth: 100,
    //                     marginRight: '5px',
    //                     p: 1,
    //                     m: 1,
    //                 }} onClick={handleClose} variant='contained' size='small' >Disagree</Button> 
    //           </Box>
    //         </Alert>
    //       </Stack>
    //     </Dialog>
      // </div>
  //  );
  };
  const showEventConfirmation = () =>  {
    
    return <AppEventConfirmationView variant="info"message={eventInfo.toString()} />;
    // return (
    //   <div>
    //     <Dialog
    //       open={true}
    //       onClose={handleClose}
    //       aria-labelledby="alert-dialog-title"
    //       aria-describedby="alert-dialog-description"

    //     >
    //       <Stack sx={{ width: '100%' }} spacing={2}>
    //         <Alert variant='filled' severity='success'>
    //           <AlertTitle>Success</AlertTitle>
              
    //           <Box sx={{ width: '100%',
    //                       height: '75px',
    //                      display: "flex",
    //                      alignItems: "center",
    //                      fontWeight: '2.4rem',
    //                      }}>
    //           record inserted successfully, would you like to return to the listing page?
    //           </Box>
    //           <Box sx={{ width: '100%',
    //                      display: "flex",
    //                      alignItems: "center",
    //                      flexDirection: "row-reverse"}}>
    //                         <Button sx={{
    //                     position: "relative",
    //                     minWidth: 100,
    //                     ml: 2.5,
    //                 }} onClick={handleClose} autoFocus  variant='contained' size='small'>Agree</Button>
    //             <Button  sx={{
    //                     position: "relative",
    //                     minWidth: 100,
    //                     marginRight: '5px',
    //                     p: 1,
    //                     m: 1,
    //                 }} onClick={handleClose} variant='contained' size='small' >Disagree</Button> 
    //           </Box>
    //         </Alert>
    //       </Stack>
    //     </Dialog>
      // </div>
  //  );
  };

  const showError = () => {
    return <AppMessageView variant="error" message={error.toString()} />;
  };
  return (
    <>
      {loading && <AppLoader />}
      {message && showMessage()}
      {eventInfo && showEventConfirmation()}
      {error && showError()}
    </>
  );
};

export default AppInfoView;
