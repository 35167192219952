import React from "react";
import Button from "@mui/material/Button";
import IntlMessages from "@crema/utility/IntlMessages";
import { alpha, Box } from "@mui/material";
import { Fonts } from "../../../../shared/constants/AppEnums";
import AppCard from "@crema/core/AppCard";
import { TotalBalanceData } from "../../mock";
import { ContactsPanelData } from "../NotificationsPanel";
import ContactsInfo from "./Info";

interface ContactsPanelProps {
  contactsPanelData: ContactsPanelData;
}

const ContactsPanel: React.FC<ContactsPanelProps> = ({ contactsPanelData }) => {
  const contactsArr: ContactsPanelData[] = [];
  contactsArr.push(contactsPanelData);
  return (
    <Box>

      <AppCard sxStyle={{height: 285, width: "100%", marginRight: "10px"}}>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          <Box
            sx={{
              position: "relative",
            }}
          >
            <Box
              component="h2"
              sx={{
                color: "text.primary",
               
                mb: { xs: 4, sm: 4, xl: 2 },
                fontWeight: Fonts.BOLD,
              }}
            >
              <IntlMessages id="contacts.title" />
            </Box>
          </Box>
        </Box>
       
        <Box
          sx={{
              pt: { md: 1, lg: 1 },
          }}
        >
          <ContactsInfo contactsInfo={contactsArr} />
        </Box>
      </AppCard>
    </Box>
  );
};

export default ContactsPanel;
