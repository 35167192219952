import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import clsx from "clsx";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import SnackbarContent from "@mui/material/SnackbarContent";
import WarningIcon from "@mui/icons-material/Warning";
import Snackbar from "@mui/material/Snackbar";
import { hideMessage } from "../../../redux/actions";
import { useDispatch } from "react-redux";
import { Box, Button, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Slide, Stack, TextField } from "@mui/material";
import { amber, green } from "@mui/material/colors";

const PREFIX = "AppMessageView";

const classes = {
  success: `${PREFIX}-success`,
  error: `${PREFIX}-error`,
  info: `${PREFIX}-info`,
  warning: `${PREFIX}-warning`,
  icon: `${PREFIX}-icon`,
  iconVariant: `${PREFIX}-iconVariant`,
  message: `${PREFIX}-message`,
};

const StyledSnackbar = styled(Snackbar)(({ theme }) => ({
  [`& .${classes.success}`]: {
    backgroundColor: green[600],
  },

  [`& .${classes.error}`]: {
    backgroundColor: theme.palette.error.main,
  },

  [`& .${classes.info}`]: {
    backgroundColor: theme.palette.primary.dark,
  },

  [`& .${classes.warning}`]: {
    backgroundColor: amber[700],
  },

  [`& .${classes.icon}`]: {
    fontSize: 20,
  },

  [`& .${classes.iconVariant}`]: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },

  [`& .${classes.message}`]: {
    display: "flex",
    alignItems: "center",
  },
}));

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

function TransitionLeft(props: any) {
  return <Slide {...props} direction="left" />;
}

interface AppMessageViewProps {
  className?: string;
  message: string;
  variant: "success" | "error" | "warning" | "info";

  [x: string]: any;
}
function BasicSelect() {
  const [status, setStatus] = React.useState('');

  const handleChange = (event: SelectChangeEvent) => {
    setStatus(event.target.value as string);
  };

  return (
    <Box sx={{ minWidth: 120, margin: 5 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label" variant="outlined">Status</InputLabel>
        <Select
          style={{ backgroundColor: "white" }}
          variant="outlined"
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={status}
          label="Status"
          onChange={handleChange}
        >
          <MenuItem value={0}>Cancelado</MenuItem>
          <MenuItem value={1}>Concluído</MenuItem>
          <MenuItem value={2}>Reagendado</MenuItem>
          <MenuItem value={3}>Adiado</MenuItem>
          <MenuItem value={4}>Sem Contato</MenuItem>

        </Select>
      </FormControl>
    </Box>
  );
}
const AppEventConfirmationView: React.FC<AppMessageViewProps> = (props) => {
  const [open, setOpen] = React.useState(false);
  const { className, message, variant, ...other } = props;
  const Icon = variantIcon[variant];
  const dispatch = useDispatch();

  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    if (message) {
      setOpen(true);
    }
  }, [message]);

  const action = (
    <React.Fragment>
      <BasicSelect />
      <Button variant="contained" color="primary" onClick={() => {
        handleClose()
        localStorage.setItem("eventChecked", "true")
        }}>Salvar</Button>
    </React.Fragment>
  )

  var show = localStorage.getItem('eventChecked');
  return (
    show !== "true"? 
    <Stack spacing={2} sx={{ maxWidth: 600 }}>
    <StyledSnackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={open}
      TransitionComponent={TransitionLeft}
    >
      <SnackbarContent
        className={clsx(classes[variant], className)}
        aria-describedby="client-snackbar"

        message={
          <span id="client-snackbar" className={classes.message}>
            <Icon className={clsx(classes.icon, classes.iconVariant)} />
            {message}
          </span>
        }
        action={action}
        {...other}
      />
    </StyledSnackbar>
    </Stack>: <div></div>
  );
};

export default AppEventConfirmationView;
